.section-container{
    width: 670px;
    padding-left: 15px;
    padding-right: 15px;
    max-width: 100%;
    margin: 0px auto;
    position: relative;
}

.section-2 .topHeader {
    padding-top: 25px;
    text-align: center;
}
.section-2 .topHeader .upsell-header {
    color: #ff0000;
    font-size: 24px;
    text-align: center;
    margin-bottom: 5px;
    display: inline-block;
    line-height: 50px;
    text-transform: uppercase;
    font-weight: bold;
}
.section-2 .topHeader .sub-header {
    text-align: center;
    font-size: 20px;
    margin-bottom: 30px;
    display: inline-block;
    color: #5a5a5a;
}
.coupon {
    background: url(../images/bioderm_upsell_background.jpg) no-repeat;
    border: 5px dashed black;
    cursor: pointer;
    width: 580px;
    margin: 0px auto;
    max-width: 100%;
}
.bottle-wrap {
    width: 220px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
}
.bottle-wrap .bottle {
    width: 220px;
}

.bottle-info {
    width: 360px;
    margin-left: -20px;
    text-align: center;
    display: inline-block;
    vertical-align: top;
    
}
.bottle-info .line-2 {
    color: #0c3d8c;
    font-weight: bold;
    font-size: 26px;
    margin-top: 70px;
}

.bottle-info .line-3 {
    color: #0c3d8c;
    height: 50px;
    font-size: 20px;
}
.bottle-info p.line-3 span {
    font-weight: bold;
    font-size: 20px;
    color: #cd4c6b;
}
.bottle-info .line-4 {
    color: #0c3d8c;
}
p.line-5 {
    font-size: 65px;
    line-height: 65px;
    font-weight: 700;
    color: #0c3d8c;
}
p.line-5 span{
    font-size: 16px;
}
div.scissors-wrap {
    text-align: right;
}
img.scissors {
    width: 80px;
    margin-right: 20px;
}
#security-tag {
    padding: 10px 0 10px 0;
    margin: 0 auto;
    color: lightgrey;
}
#security-tag > p {
    text-align: center;
    font-size: 20px;
    margin-bottom: 0;
}
#security-tag img#up-lock-two {
    position: relative;
    top: -1px;
}
.upsellBtnDiv {
    background: #222222;
}

.btnDiv-inner{
    padding: 0 0 30px;
}

.btnDiv-inner img{
    max-width: 300px;
}

.footer-wrap {
    background-color: #000;
    margin-top: -20px;
    padding-bottom: 30px;
    color: #9a9494;
    font-size: 14px;
}

.footer-wrap-logo{
    background-color: #222222;
    padding-bottom: 20px;
    padding-top: 10px;
}
.footer-wrap img.secure {
    display: block;
    margin: 0px auto;
}
.footer-wrap h1.privacy {
    color: grey;
    text-align: center;
    margin-bottom: 10px;
}

.footer-wrap h1.privacy a{
    font-size: 15px;
    color: #aaa;
    font-weight: normal;
    padding-top: 10px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 10px;
}
.footerTxt {
    padding: 10px;
    color: #9a9494 !important;
    font-size: 13px;
}
.footerTxt a{
    display: inline-block;
}
.footer-wrap .footerBtm a.footerlink {
    color: #9a9494;
    display: inline-block;
    font-size: 22px;
    cursor: pointer;
    text-decoration: none;
}
p.copyRightTxt {
    color: #9a9494;
    font-size: 12px;
}

.faceCreamUpsell-3{
    padding: 30px 0 60px;
}


.medImage{
    margin-bottom: 30px;
}
.medImage img {
    width: 850px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.fcUpsell3-btn{
    margin-bottom: 30px;
}
.bottom-nothanks a {
    background: transparent;
    border: 0;
    color: #000;
    box-shadow: none;
    font-size: 12px;
    background: transparent;
    border: none;
    color: #8e8e8e;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.5s;
}
.bottom-nothanks a:hover{
    background: #dc3545;
    color: #fff;
    text-decoration: none;
}
.bottom-nothanks a:hover .text-danger{
    color: #fff !important;
}










/* Responsive  */
@media only screen and (max-width: 767.9px){
    .section-2 .topHeader .upsell-header{
        font-size: 20px;
        line-height: 25px;
    }
    .section-2 .topHeader .sub-header{
        font-size: 15px;
    }
    .bottle-wrap,
    .bottle-info {
        width: 100%;
        display: block;
        padding: 0 15px;
    }
    .coupon {
        background: #d9f4fb;
    }
    .bottle-info {
        margin: 0;
    }
    .bottle-info .line-2{
        font-size: 20px;
        margin-top: 30px;
    }
    .bottle-info .line-3{
        height: auto;
        font-size: 20px;
    }
    p.line-5 {
        font-size: 40px;
        line-height: 50px;
    }
    #security-tag > p{
        font-size: 16px;
    }
    .footer-wrap .footerBtm{
        padding: 0 15px;
    }
    .footer-wrap .footerBtm a.footerlink{
        font-size: 15px;
    }
    .footer-links{
        padding: 0 10px;
    }
    .footer-links li:first-child {
        padding-left: 0;
    }
    .footer-links li:last-child {
        padding-right: 0;
    }
}