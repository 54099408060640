.checkoutMain {
    background: #1e1e2d;
    padding-top: 10px;
}

div#layoutSidenav_content .checkout-container.container {
    padding: 0 !important;
}

.checkout-container.container {
    max-width: 1140px;
}

.card.funnel_card {
    /* background: #f1f1f1 ; */
}
.templateMaster-1{    
    /* padding-bottom: 60px; */
    background: url('../assets/img/inner-banner-bg.jpg') no-repeat left top;
    background-color: #000;
    padding-top: 30px;
}
.masterShipAddr input#shippingCheckbox{
    position: absolute;
    left: 3px;
    top: -7px !important;
    margin: 0;
}
.site-secure {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
}
.orderWrapper {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 6px;
    background-color: #fff;
}

/* styles.css */
.wrapper {
    padding: 0 0.5ch;
}

.wrapperText {
    color: whitesmoke;
}

.wrapperTextdark {
    color: #000000;
}

.textEdit {

    text-decoration: underline;
}

.orderWrapper-left {
    -ms-flex: 0 0 65%;
    flex: 0 0 65%;
    max-width: 65%;
    padding-right: 15px;
}

.orderWrapper-right {
    -ms-flex: 0 0 35%;
    flex: 0 0 35%;
    max-width: 35%;
    padding-left: 15px;
}


.plan-selection {
    margin-bottom: 15px;
}


.payProd {
    border: solid 1px #14345c;
    -moz-box-shadow: 0 0 9px rgba(41, 32, 30, .15);
    -webkit-box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    box-shadow: 0 0 9px rgb(41 32 30 / 15%);
    border-radius: 7px;
}

.payProd_header {
    background: rgb(70, 70, 70);
    background: linear-gradient(6deg, rgba(70, 70, 70, 1) 0%, rgba(61, 61, 61, 1) 50%, rgba(52, 52, 52, 1) 100%);
    color: #fff;
    padding: 10px;
    position: relative;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.payProd_header p {
    margin: 0;
    font-size: 18px;
    font-weight: 700;
    text-shadow: 0 1px 0 #000;
}

.shipping_msg {
    position: absolute;
    bottom: 0;
    right: 10px;
    background: #ea7901;
    padding: 5px 15px;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.payProd_body {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 10px 45px;
    position: relative;
}

.product_figure {
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
    max-width: 60%;
    display: flex;
    align-items: center;
}

.product_cont {
    -ms-flex: 0 0 40%;
    flex: 0 0 40%;
    max-width: 40%;
    line-height: 18px;
    font-size: 14px;
}

.text_red1 {
    color: #d02734;
}

.prod_name {
    font-size: 18px;
    display: block;
    line-height: 1;
    font-style: italic;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 15px;
    letter-spacing: -.03em;
    color: #d02734;
}

.prod_offer_price {
    font-weight: 700;
}

.prod_actual_price {
    font-size: 36px;
    line-height: 34px;
    color: #0975cf;
    font-weight: 700;
}

.prod_actual_price em {
    font-size: 12px;
    vertical-align: middle;
    line-height: 34px;
}

.selected_pack .payProd {
    background-color: #feeeb0 !important;
}

.radio {
    position: absolute;
    left: 15px;
    top: 89px;
    width: 25px;
    height: 25px;
    overflow: hidden;
    border: 2px solid #8b8585;
    background: #fff;
}

.radio::before {
    position: absolute;
    content: "";
    height: 7px;
    width: 15px;
    border-left: 3px solid #0975cf;
    border-bottom: 3px solid #0975cf;
    transform: rotate(-45deg) translate(-1px, 6px);
    opacity: 0;
}

.selected_pack .radio::before {
    opacity: 1;
}

.order-delivery {
    min-height: 104px;
    font-size: 15px;
    padding: 22px 0 0 125px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
}

.icon_delivery {
    position: absolute;
    left: 15px;
    top: 15px;
    width: 90px;
}

.order-delivery h3 {
    font-size: 20px;
    letter-spacing: -0.5px;
    padding: 0 0 8px;
    font-weight: bold;
}

.order-counter {
    font-size: 14px;
    padding: 10px 20px;
    margin: 0 0 20px;
    position: relative;
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -moz-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    -o-box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    box-shadow: 0 0 24px rgba(55, 38, 52, 0.15);
    font-weight: 500;
}

.order-counter article {
    padding: 14px 0 14px;
    border-bottom: 1px dashed #ddd;
}

.clearfix:before,
.clearfix:after {
    display: table;
    line-height: 0;
    content: '';
}

.float_left {
    float: left;
}

.float_right {
    float: right;
}

.order-counter article.last {
    border: none;
}

.order-counter article b {
    color: #FF1C1C;
}

.order-satisfaction {
    min-height: 139px;
    font-size: 13px;
    line-height: 18px;
    padding: 24px 20px 6px 140px;
    margin: 0 0 20px;
    border: 2px dashed #ddedf9;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
    position: relative;
}

.order-satisfaction .gurantee_img {
    position: absolute;
    left: 15px;
}

.order-satisfaction h3 {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: -1px;
}

.order-satisfaction p {
    line-height: 22px;
    font-size: 16px;
}

.order-special header div span {
    font-weight: 500;
    font-size: 24px;
    margin: 0 5px;
}

.arrive-date {
    padding: 20px;
    font-size: 14px;
}


.formBox-body {
    padding: 10px;
    padding-top: 15px;
}

.formBox-hdr img {
    width: 100%;
}

.payOption {
    margin-bottom: 30px;
}

.checkout-form-div {
    margin-bottom: 10px;
}

.checkout-form-div .pay-btn {
    display: block;
}

.checkout-form-div .form_row {
    margin-right: -7.5px;
    margin-left: -7.5px;
}

.checkout-form-div .form_col {
    margin-bottom: 10px;
    padding-left: 7.5px;
    padding-right: 7.5px;
}

.checkout-form-div .form-control {
    height: 33px;
    padding: 5px 10px;
    text-indent: 9px;
    border: 1px solid #999;
    border-radius: 0;
    text-indent: 0 !important;
}

.checkout-form-div .pay-btn {
    width: 100% !important;
    text-align: center;
    border: none;
    height: 73px;
    width: 100%;
    cursor: pointer;
    background-size: contain;
    box-shadow: none;
    margin-top: 0;
    animation-name: pulse;
    -webkit-animation-name: pulse;
    animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}
.checkoutPageWrapper  .checkboxLabel{
    padding-left: 30px;
}
@keyframes pulse {
    0% {
        transform: scale(0.9);
        opacity: 0.9;
    }

    50% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(0.9);
        opacity: 0.9;
    }
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }

    50% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.95);
        opacity: 0.7;
    }
}


.section-guranteed {
    margin-bottom: 0;
}

.section-guranteed .w_inner {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    margin-top: 15px;
}

.section-guranteed .w_inner .w_thumb {
    order: 2;
    width: 100%;
}

.section-guranteed .w_inner .w_thumb img {
    width: auto;
}

.section-guranteed .w_inner .w_desc {
    order: 1;
    width: 100%;
    position: relative;
}

.section-guranteed .w_inner .w_desc p {
    display: table;
    margin: 0 auto;
    padding: 5px 10px;
    background: #fff;
    position: relative;
    z-index: 2;
}

.section-guranteed .w_inner .w_desc p strong {
    color: #29af5c;
}

.section-guranteed .w_inner .w_desc:before {
    content: "";
    width: 100%;
    border-top: 2px solid #29AF5C;
    display: block;
    position: relative;
    top: 17px;
    z-index: 0;
}



.yellowbox {
    border: 2px dotted red;
    background: #ffe11d;
    padding: 20px 10px;
    display: table;
    position: relative;
    width: 100%;
}

.yellowbox .pulse {
    width: 25px;
}

.yellowbox i,
.check_area {
    display: table-cell;
    vertical-align: middle;
}

.check_area {
    padding-left: 10px;
}

.check_area [type="checkbox"]:not(:checked),
.check_area [type="checkbox"]:checked {
    position: absolute;
    left: -9999px;
}

.check_area [type="checkbox"]:not(:checked)+label,
.check_area [type="checkbox"]:checked+label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 90%;
    font-weight: bold;
}

.check_area.check_area [type="checkbox"]:not(:checked)~label:before,
.check_area [type="checkbox"]:checked~label:before {
    content: '';
    position: absolute;
    left: -2px;
    /* top: 50% !important;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%); */
    width: 18px;
    height: 18px;
    border: 1px solid #b9b9b9;
    /* border: none; */
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.check_area [type="checkbox"]:not(:checked)~label:after,
.check_area [type="checkbox"]:checked~label:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 5px;    
    left: 2px;
    top: 5px;
    color: #09ad7e;
    transition: all .2s;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
}

.check_area.check_area [type="checkbox"]:not(:checked)~label:before,
.check_area [type="checkbox"]:checked~label:before {
    content: '';
    position: absolute;
    left: -2px;
    height: 18px;
    border: 1px solid #b9b9b9;
    /* border: none; */
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgb(0 0 0 / 10%);
}

.check_area label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin: 0;
}

.check_area [type="checkbox"]:checked~label:before {
    background: #1063a0;
    /* transform: none;
    top: 0px !important; */
}
/* .check_area [type="checkbox"]:checked~label:after{
    transform: rotate(-45deg) translate(10px, -7px);
} */
.orderWrapper-right .formBox  .check_area [type="checkbox"] ~label:before{
    transform: none;
    top: 0px !important;
}
/* .orderWrapper-right .formBox .check_area [type="checkbox"]~label:after{
    transform: rotate(-45deg) translate(10px, -7px);
} */
.top-terms {
    clear: both;
    text-align: left;
    width: 100%;
    margin: 25px auto 20px;
}

.top-trends-desc {
    display: none;
}

.open .top-trends-desc {
    display: block;
}

.top-termsHdng {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    border-bottom: 1px solid #e1e1e1;
    padding: 15px 30px 15px 2px;
}

.top-termsHdng:after {
    content: '+';
    position: absolute;
    right: 10px;
    top: 50%;
    color: #afafaf;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;
    background-size: 100%;
    transform: translateY(-50%);
}

.top-terms.open .top-termsHdng:after {
    content: '-';
}

.top-trends-desc p {
    padding: 5px 5px 5px;
    text-align: left;
    color: #3d3d3d;
    font-size: 14px;
    line-height: 23px;
    letter-spacing: 1px;
    margin: 0;
    font-weight: 500;
}

.securityIcon-sec .container .order-icons-list {
    background: #fff;
    padding: 30px 15px 10px;
    border-radius: 6px;
    text-align: center;
    margin-bottom: 0;
}

.order-icons-list li {
    display: inline-block;
    padding: 0 5px;
    list-style: none;
}

.order-icons-list li:last-child {
    display: block;
}



.footerWrapper {
    padding: 30px 0;
    background-color: #000;
    z-index: 2;
    position: relative;
}

.footer-links {
    text-align: center;
}

.footer-links li {
    list-style: none;
    display: inline-block;
    padding: 0 10px;
    font-size: 12pt;
    text-transform: capitalize;
    position: relative;
}

.footer-links li a {
    color: #fff !important;
}

.copy-right-txt {
    color: #fff;
    text-align: center;
    margin-top: 3px;
    font-size: 11px;
    line-height: 17px;
    padding: 0 15px;
}

.not-selected-msg {
    font-size: 18px;
    text-align: center;
    font-weight: 700;
}


/* Responsive  */
@media only screen and (max-width: 991.8px) {
    .orderWrapper,
    .newCheckout-wrapper{
        padding: 10px !important;
    }
    .orderWrapper-left{
        padding-right: 0;
    }
    .check_area [type="checkbox"]:not(:checked)~label:after, .check_area [type="checkbox"]:checked~label:after{
        /* transform: rotate(-45deg) translate(-3px, 5px); */
    }
}
@media only screen and (max-width: 767.8px) {

    div#layoutSidenav_content .container.funnel-container,
    div#layoutSidenav_content .container.securityIcon-container {
        padding: 15px !important;
    }

    .orderWrapper-left,
    .orderWrapper-right {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 0px;
        padding-left: 0px;
    }

    .product_figure,
    .product_cont {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .orderWrapper-right {
        margin-top: 30px;
    }

    .order-icons-list li img {
        margin-bottom: 15px;
        max-width: 100%;
    }

    .shipping_msg {
        position: absolute;
        padding: 5px 5px;
        font-size: 10px;
        right: 5px;
    }

    .payProd_header p {
        font-size: 14px;
    }

    .order-delivery {
        padding-left: 15px !important;
        padding-right: 15px;
    }

    .icon_delivery {
        position: relative;
        left: unset;
        top: unset;
        width: 90px;
        margin: 0 auto 15px;
        display: block;
    }

    .order-delivery h3 {
        font-size: 16px;
        padding: 0;
    }

    .payProd_header {
        padding-left: 35px;
    }

    .payProd_body {
        padding: 15px;
    }

    .radio {
        position: absolute;
        left: 7px;
        top: -33px;
    }

    .product_figure {
        margin-bottom: 15px;
    }

    .product_cont {
        text-align: center;
    }

    .order-satisfaction {
        padding: 15px;
    }

    .order-satisfaction .gurantee_img {
        position: relative;
        left: unset;
        display: block;
        margin: 0 auto 15px;
        text-align: center;
    }
    #layoutSidenav_content .cardBg-1 {
        padding: 15px 0;
    }
   
}

@media only screen and (max-width: 480.8px) {
    .payProd_header p {
        font-size: 11px;
    }

    .radio {
        width: 20px;
        height: 20px;
        left: 6px;
        top: -28px;
    }
    .radio::before{
        transform: rotate(-45deg) translate(-2px, 4px);
        height: 6px;
        width: 12px;
    }

    .payProd_header {
        padding-left: 30px;
    }
}