html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	font-size: 16px;
	font-family: 'Open Sans', sans-serif !important;
}
body {
	margin: 0;
	line-height: 1.5;
	font-family: 'Open Sans', sans-serif !important;
}
@media (min-width: 1400px){
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px !important;
    }
}
main {
	display: block;
	line-height: 1.5;
}
h1 {
	font-size: 2em;
	margin: .67em 0
}
hr {
	-webkit-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible
}
pre {
	font-family: monospace, monospace;
	font-size: 1em
}
a {
	background-color: transparent
}
abbr[title] {
	border-bottom: none;
	text-decoration: underline;
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted
}
b, strong {
	font-weight: bolder
}
code, kbd, samp {
	font-family: monospace, monospace;
	font-size: 1em
}
small {
	font-size: 80%
}
sub, sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline
}
sub {
	bottom: -.25em
}
sup {
	top: -.5em
}
img {
	border-style: none
}
button, input, optgroup, select, textarea {
	font-family: inherit;
	font-size: 100%;
	line-height: 1.15;
	margin: 0
}
button, input {
	overflow: visible
}
button, select {
	text-transform: none
}
[type=button], [type=reset], [type=submit], button {
-webkit-appearance:button
}
[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
border-style:none;
padding:0
}
[type=button]:-moz-focusring, [type=reset]:-moz-focusring, [type=submit]:-moz-focusring, button:-moz-focusring {
outline:1px dotted ;
}
fieldset {
	padding: .35em .75em .625em
}
legend {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal
}
progress {
	vertical-align: baseline
}
textarea {
	overflow: auto
}
[type=checkbox], [type=radio] {
-webkit-box-sizing:border-box;
box-sizing:border-box;
padding:0
}
[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
height:auto
}
[type=search] {
-webkit-appearance:textfield;
outline-offset:-2px
}
[type=search]::-webkit-search-decoration {
-webkit-appearance:none
}
::-webkit-file-upload-button {
-webkit-appearance:button;
font:inherit
}
details {
	display: block
}
summary {
	display: list-item
}
template {
	display: none
}
[hidden] {
display:none
}
.row:after, .row:before {
	content: "";
	display: block;
	clear: both
}
body {
	/*font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;*/
	font-family: 'Open Sans', sans-serif;
}
ul {
	padding: 0;
	margin: 0;
	list-style-type: none
}
h1, h2, h3, h4, p {
	margin: 0;
	padding: 0
}
/* .container {
	max-width: 1000px;
	display: block;
	margin: 0 auto
} */


.row > div {
    padding-left: 15px;
    padding-right: 15px;
}


header#at_page_header{
	display: none !important;
}

/* Diet Page */
.diet-page {
    /* padding-bottom: 70px; */
}
.diet-header {
    background: #253b88;
    padding-bottom: 10px;
    position: fixed;
    width: 100%;
    text-align: center;
    z-index: 22;
	top: 0;
	letter-spacing: 0;
	width: 100%;
}
.diet-header:before {
	content: "";
    width: 112px;
    height: 112px;
    background: url(../images/verify-icon.png);
    position: absolute;
    top: 0;
    left: 0;
}
.diet-header__warning {
    background-color: #000;
    text-align: center;
    font-size: 15px;
    padding: 6px 15px;
    color: #fff;
}
.diet-page .date-container,.diet-page .hurry,.diet-page .timer {
    color: #f5f0b4;
}

.diet-header__logo {
    margin-top: 21px;
    max-width: 122px;
}
.first-section {
	padding-top: 150px;
    padding-bottom: 26px;
    background: url(../images/model.jpg) no-repeat 120% 100%;
    background-size: auto 86%;
}
@media (max-width: 1750px){
    .first-section {
        background: url(../images/model.jpg) no-repeat 110% 100%;
        background-size: auto 87%;
    }
}
@media (max-width: 1540px){
    .first-section {
        background: url(../images/model.jpg) no-repeat 116% 100%;
        background-size: auto 87%;
    }
}
@media (max-width: 1440px){
    .first-section {
        padding-bottom: 26px;
        background: url(../images/model.jpg) no-repeat 120% 100%;
        background-size: auto 86%;
    }
}

.first-section__info{
	padding-top: 11px;
    position: relative;
}
.first-section__info h1 {
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 110px;
    letter-spacing: -.09em;
    color: #253b88;
    margin-top: -20px;
}
.first-section__info h1 span {
    font-style: italic;
}
.first-section__info h2 {
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 82px;
    letter-spacing: -.01em;
    margin-top: -27px;
    color: #000;
}
.first-section__info h3 {
    font-family: Oswald, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 46px;
    letter-spacing: -.03em;
    max-width: 417px;
    color: #7fc407;
}

.first-section__info ul {
    margin-top: 20px;
}
.first-section__info ul li {
    position: relative;
    font-family: 'Roboto Condensed', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -.02em;
    color: #000;
    padding-left: 20px;
    margin-bottom: 8px;
}
.first-section__info ul li:before {
    content: "";
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 50%;
    left: 0;
    top: 13px;
    position: absolute;
    background: #ca3070;
}
.security-icons {
    display: block;
    margin: -32px 0 0 51px;
}
/* .first-section__bottle {
    position: absolute;
    bottom: -39px;
    left: 520px;
    right: auto;
    max-width: 154px;
} */
.first-section__bottle:before {
    content: "";
    background: url(../images/sprite1.png) 0 -148px no-repeat;
    height: 89px;
    width: 98px;
    position: absolute;
    left: -46px;
    top: -33px;
	opacity: 1;
}
.first-section__bottle img {
    width: 100%;
}
.first-section__bottle:after {
    content: "";
    width: 119px;
    height: 292px;
    background: url(../images/sprite1.png) 0 -235px no-repeat;
    position: absolute;
    right: -93px;
    top: -89px;
}
.garante-list{display: none;}
.why-is {
    background-color: #f7bd1a;
    background-image: -o-linear-gradient(top, #f8cc1c 0, #f58d13 100%);
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(#f8cc1c), to(#f58d13)); */
    background-image: linear-gradient(top, #f8cc1c 0, #f58d13 100%);
}
.why-is__side {
    float: left;
    width: 47%;
    padding: 30px 1.5% 0;
    position: relative;
}
.keto-magazines {
    max-width: 100%;
}
.wy-btl {
    position: absolute;
    bottom: 20px;
    left: 41%;
    width: 33%;
}
.why-is__side {
    float: left;
    width: 47%;
    padding: 30px 1.5% 0;
    position: relative;
}
.why-is__side h2 {
    font-family: Oswald, sans-serif;
    font-weight: 700;
    color: #fff;
    font-size:54px;
    margin-bottom: 20px;
    margin-top: 61px;
}
.why-is__side h2 span {
    color: #253b88;
}
.why-is__side p {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 1.25rem;
    color: #fff;
}
/* Diet Page End */
.header__warning {
	background-color: #000;
	padding: 5px 0;
	text-align: center;
	font-size: 15px;
	color: #fff
}
.header {
	background: #253b88;
	border-bottom: 2px solid #252525;
	padding-bottom: 10px;
	position: fixed;
	width: 100%;
	z-index: 22
}
.header:before {
	content: "";
	width: 112px;
	height: 112px;
	background: url(../images/verify-icon.png);
	position: absolute;
	top: 0;
	left: 0
}
.header__logo {
	float: left;
	margin-top: 8px;
	max-width: 100%
}

#email, #firstName, #lastName, #phone {
	width: 48%;
	display: inline-block
}
.date-container, .hurry {
	color: #f5f0b4!important
}
#email, #firstName {
	margin-right: 4%
}
.header__info ,
.diet-header__info{
	float: right;
	width: calc(100% - 190px);
	margin-top: 16px
}
.header__btn,
.diet-header__btn {
	background-color: #5fbb49;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: -o-linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#a3cc2c), to(#5fbb49))!important; */
	background-image: linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
	width: 230px;
	line-height: 56px;
	text-align: center;
	text-decoration: none;
	text-shadow: 1px 0 0 #6ba605;
	color: #fff;
	text-transform: uppercase;
	display: block;
	border-radius: 10px;
	font-size: 20px;
	font-weight: 700;
	float: right;
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s
}
.header__btn:hover,
.diet-header__btn:hover {
	/* background-image: -webkit-gradient(linear, 10% 0, 20% 90%, color-stop(4%, #a3cc2c), color-stop(100%, #5fbb49))!important; */
	-webkit-transition: all .5s;
	-o-transition: all .5s;
	transition: all .5s;
	color : #fff;
}
.header__info_side,
.diet-header__info_side {
	float: left;
	width: calc(100% - 270px);
	text-align: right;
	margin-top: 5px;
	margin-right: 40px
}
.header__info_side span,
.diet-header__info_side span {
	display: block;
	font-style: italic;
	font-size: 24px;
	line-height: 24px;
	color: #fff
}
.header__info_side p,
.diet-header__info_side p {
	color: #fff;
	margin-top: 5px;
	font-size: 16px
}
.header__info_side p span ,
.diet-header__info_side p span{
	display: inline-block;
	color: #00eaff;
	font-weight: 400;
	font-size: 16px
}
.first-section .first-section__side {
	width: 62%;
	float: left
}
.first-section .first-section__side:last-child {
	width: 37%;
	margin-left: 1%
}


.kform .row{
	margin-left: 0;
	margin-right: 0;
}

.kform .row > div{
	padding-left: 0;
	padding-right: 0;
}

.kform  .select2-container{
	max-width: 100% !important;
	height: 40px;
    margin-bottom: 15px;
	border: 1px solid #989898;
    -webkit-box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);
    box-shadow: inset 0 1px 2px rgb(0 0 0 / 40%);
    color: rgba(0,0,0,.75);
}
.kform  .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: rgba(0,0,0,.75);
    font-size: 14px;
}
.form-title {
	font-size: 35px;
	font-weight: 700;
	color: #262626;
	text-transform: uppercase;
	max-width: 400px;
	font-family: "Open Sans", sans-serif;
}
.form-title span {
	color: #ff5a00
}
.diet-page form {
	margin-top: 17px
}
.diet-page form label {
	font-size: 12px;
	color: #4d4d4d;
	display: block;
	margin-bottom: 4px;
	font-weight: 400
}
.diet-page form input, .diet-page form select {
	-webkit-appearance: none;
	background-color: #fff;
	font-family: inherit;
	border: 1px solid #989898;
	-webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
	box-shadow: inset 0 1px 2px rgba(0,0,0,.4);
	color: rgba(0,0,0,.75);
	display: block;
	font-size: 1rem;
	margin: 0 0 10px;
	padding:.5rem;
	height: 40px;
	width: 100%;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-transition: border-color .45s ease-in-out, -webkit-box-shadow .45s;
	transition: border-color .45s ease-in-out, -webkit-box-shadow .45s;
	-o-transition: box-shadow .45s, border-color .45s ease-in-out;
	transition: box-shadow .45s, border-color .45s ease-in-out;
	transition: box-shadow .45s, border-color .45s ease-in-out, -webkit-box-shadow .45s;
	line-height: normal;
	border-radius: 0;
	color: #787878;
}
.diet-page form .row .form-holder {
	width: 48%;
	float: left
}
.diet-page form .row .form-holder:first-child {
	margin-right: 4%
}
.button-submit {
	background-color: #5fbb49;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-image: -o-linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#a3cc2c), to(#5fbb49))!important; */
	background-image: linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
	line-height: 74px;
	text-align: center;
	text-decoration: none;
	text-shadow: 1px 0 0 #6ba605;
	color: #fff;
	text-transform: uppercase;
	display: block;
	border-radius: 10px;
	font-size: 26px;
	font-weight: 700;
	border: none;
	width: 100%;
	cursor: pointer;
	margin-top: 11px;
	-webkit-transition: all .2s;
	-o-transition: all .2s;
	transition: all .2s
}
.button-submit:hover {
	background: #253b88!important
}

.first-section__info h1 {
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 110px;
	letter-spacing: -.09em;
	color: #253b88;
	margin-top: -20px
}
.first-section__info h1 span {
	font-style: italic
}
.first-section__info h2 {
	font-family: Oswald, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 55px;
	line-height: 82px;
	letter-spacing: -.01em;
	margin-top: -27px;
	color: #000
}
.first-section__info {
	padding-top: 11px;
	position: relative
}
.first-section__info h3 {
	font-family: Oswald, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 46px;
	letter-spacing: -.03em;
	max-width: 417px;
	color: #7fc407
}
.first-section__info ul {
	margin-top: 20px;
	padding-left: 0;
}
.first-section__info ul li {
	position: relative;
	font-family: 'Roboto Condensed', sans-serif;
	font-style: normal;
	font-weight: 700;
	font-size: 30px;
	line-height: 35px;
	letter-spacing: -.02em;
	color: #000;
	padding-left: 20px;
	margin-bottom: 8px
}
.first-section__info ul li:before {
	content: "";
	display: block;
	width: 9px;
	height: 9px;
	border-radius: 50%;
	left: 0;
	top: 13px;
	position: absolute;
	background: #ca3070
}
.security-icons {
	display: block;
	margin: -32px 0 0 51px
}
.first-section__bottle {
	position: absolute;
	bottom: 22px;
	right: 105px;
	max-width: 230px;
}
.first-section__bottle:before {
	content: "";
	background: url(../images/sprite1.png) 0 -148px no-repeat;
	height: 89px;
	width: 98px;
	position: absolute;
	left: -5px;
	top: -3px;
	opacity: 1;
}
.first-section__bottle:after {
	content: "";
	width: 119px;
	height: 292px;
	background: url(../images/sprite1.png) 0 -235px no-repeat;
	position: absolute;
	right: -93px;
	top: -89px
}
.first-section__bottle img {
	width: 100%
}
/* .why-is__side {
	float: left;
	width: 47%;
	padding: 30px 1.5% 0;
	position:relative;
} */
.why-is {
	background-color: #f7bd1a;
	background-image: -o-linear-gradient(top, #f8cc1c 0, #f58d13 100%);
	/* background-image: -webkit-gradient(linear, left top, left bottom, from(#f8cc1c), to(#f58d13)); */
	background-image: linear-gradient(top, #f8cc1c 0, #f58d13 100%)
}
.why-is__side h2 {
	font-family: Oswald, sans-serif;
	font-weight: 700;
	color: #fff;
	font-size: 3.24rem;
	margin-bottom: 20px;
	margin-top: 61px
}
.why-is__side h2 span {
	color: #253b88
}

.why-is__side h2 span.prod-name{
	color: #fff;
}
.why-is__side p {
	font-size: 1rem;
	line-height: 1.4;
	margin-bottom: 1.25rem;
	color: #fff
}
.why-is__side p:last-child {
	font-weight: 700
}
.keto-magazines{
	max-width: 100%;
	/* margin-bottom: -4px */
}
.wy-btl{
    position: absolute;
    bottom: 25px;
    left: 204px;
    width: 164px;
}
/* .how-use__item {
	float: left;
	width: calc(33.33% - 30px);
	padding: 0 15px
} */


.how-use__item img {
	display: block;
	margin: 0 auto
}
.how-use__step {
	font-weight: 700;
	font-size: 23px;
	display: block;
	font-family: Oswald, sans-serif
}
.how-use__title {
	display: block;
	font-size: 23px;
	color: #a2d408;
	font-weight: 700;
	font-family: Oswald, sans-serif
}
.how-use__item p {
	font-size: 1rem;
	line-height: 1.4;
	margin-bottom: 1.25rem;
	margin-top: 10px;
	font-family: 'Open Sans', sans-serif;
}
.how-use__titleMain {
	font-family: Oswald, sans-serif;
	font-size: 3.3125rem;
	text-align: center;
	margin-bottom: 1.875rem
}
.how-use__titleMain span {
	color: #253b88
}
svg.diagonal-line {
	width: 100%;
	height: 100%
}
.how-use {
	overflow: hidden;
	position: relative;
    z-index: 1;
}
.how-use .container {
	padding-top: 36px;
	padding-bottom: 54px
}
.container {
	position: relative
}
.diagonal-line-container {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1
}
.science {
	overflow: hidden;
	background-color: #a3cc2c;
	background-image: -o-linear-gradient(top, #a3cc2c 0, #5fbb49 100%);
	background-image: linear-gradient(top, #a3cc2c 0, #5fbb49 100%)
}
/* .science__left {
	float: left;
	width: calc(58% - 30px);
	padding: 0 15px
}
.science__right {
	float: left;
	width: calc(42% - 30px);
	padding: 0 15px
} */
.diagonal-line-container.flip svg.diagonal-line {
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
	z-index: 2
}
.green-gradient svg.diagonal-line polygon {
	fill: rgba(255,242,0,.16)
}
.science .container {
	padding-bottom: 80px
}
.science__img {
	position: relative;
	margin-top: 67px
}
.science .row {
	position: relative;
	z-index: 2
}
.science .diagonal-line-container {
	z-index: 1
}
.science__img:before {
	content: "";
	display: block;
	width: 148px;
	height: 148px;
	position: absolute;
	left: 0;
	top: -42px;
	background: url(../images/sprite1.png) 0 0 no-repeat
}
.science__title {
	color: #fff;
	font-family: Oswald, sans-serif;
	font-weight: 700;
	font-style: normal;
	font-size: 3.3125rem;
	margin-top: 40px
}
.science__subTitle {
	color: #083b0b;
	font-family: Oswald, sans-serif;
	font-weight: 700;
	font-size: 3.3125rem;
	font-style: normal
}
.science__subTitle span {
	font-size: 1.25rem
}
.science__subTitle > span {
    display: inline-block;
    position: relative;
    bottom: 5px;
    left: 5px;
}
.science__left p {
	font-family: inherit;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4;
	margin-bottom: 1.25rem;
	color: #fff;
	margin-top: 1.875rem
}
.science__left ul {
	margin-left: 1.1rem;
	padding-left: 0;
}
.science__left ul li {
	font-family: inherit;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4;
	color: #fff;
	position: relative;
margin-bottom:.75rem
}
.science__left ul li:before {
	content: "";
	display: block;
	width: 5px;
	height: 5px;
	background: #603603;
	position: absolute;
	left: -15px;
	top: 7px;
	border-radius: 50%
}
.science__left ul li span {
	text-decoration: underline;
	font-weight: 700
}
.slide__item_left {
	/* width: 50%;
	float: left */
}
.slide__item_right {
	/* width: calc(50% - 100px);
	float: left;
	display: table */
}
.slide__item_right > div{
	padding-left: 100px;
}
.slide__item {
	outline: 0
}
.slide__item_left img {
	width: auto
}
.slide__item_right p {
	font-size: 1rem;
	line-height: 1.4;
	margin-bottom: 1.25rem;
	margin-top: 108px
}
.slide__item_right span {
	display: block;
	text-align: right;
	font-weight: 700;
	font-size: 1rem
}
.slide__item_right span.prod-name {
    display: inline-block;
}
.review{
    z-index: 1;
    position: relative;
}
.review h2 {
	font-family: Oswald, sans-serif;
	font-size: 3.3125rem;
	text-align: center;
	text-transform: uppercase;
	margin: 50px 0 30px;
	display: inline-block;
	width: 100%
}
.review h2 span {
	color: #ed1973
}
.slide__item_right {
	padding-right: 100px
}
.review .container {
	padding-bottom: 90px
}
.slick-dots {
	position: absolute;
	bottom: -38px;
	display: block;
	width: 100%;
	padding: 0;
	margin: 0;
	list-style: none;
	text-align: center
}
.slick-dots li {
	position: relative;
	display: inline-block;
	width: 20px;
	height: 20px;
	background-color: #c2c2c2 !important;
	border-radius: 50%;
	margin: 0 5px;
	padding: 0;
	cursor: pointer
}
.slick-dots li.slick-active ,
.carousel-indicators li.active {
	background-color: #858383 !important;
}
.carousel-indicators{
	bottom: -38px;
}
.slick-dots li button,
.carousel-indicators li ,
.carousel-indicators [data-bs-target]{
	font-size: 0;
    line-height: 0;
    display: block;
    width: 20px !important;
    height: 20px !important;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    background-color: #c2c2c2 !important;
    outline: 0;
    background: 0 0;
    border-radius: 100%;
    transform: none !important;
    border: none !important;
    box-sizing: border-box;
}
.carousel-indicators li {
    margin: 0 5px !important;
    display: inline-block;
	padding: 0 !important;
}

.last-block{
    position: relative;
    z-index: 1;
}
.last-block__block .last-block_left, .last-block__block .last-block_right {
	width: 33%;
	float: left
}
.last-block__block .last-block_left {
	padding-top: 40px
}
.last-block__block .last-block_right {
	width: 39%;
	margin-left: -6%;
	margin-bottom: -7px
}
.last-block__block .last-block_middle {
	width: 34%;
	float: left;
	padding-top: 20px
}
.last-block__block {
	border-bottom: 3px solid #ccc;
	border-top: 3px solid #ccc;
	align-items: flex-end;
}
.last-block__block.row {
    padding-bottom: 20px;
}
.last-block__block img {
	max-width: 100%
}
.last-block__block .last-block_left h2 {
	font-family: Oswald, sans-serif;
	font-style: normal;
	font-weight: 600;
	font-size: 54px;
	line-height: 112.3%;
	margin-bottom: 11px;
	max-width: 440px;
	letter-spacing: -.035em;
	color: #8fc00c
}
.last-block__block .last-block_left h3 {
	font-family: Oswald, sans-serif;
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 118.3%;
	letter-spacing: -.055em;
	color: #000
}
.last-block__block .last-block_left ul {
	margin-top: 20px;
	padding-left: 0;
	margin-bottom: 0;
}
.last-block__block .last-block_left ul li {
	display: inline-block;
	width: 25%;
	float: left;
	text-align: center;
	padding: 0 5px;
}
.last-block__block .last-block_left ul li:last-child {
	margin-top: 5px
}
.last-block-bottle {
	max-width: 265px;
	display: block;
	position: relative;
	margin: 1px auto 15px
}
.last-block-bottle:before {
	content: "";
	display: block;
	width: 100px;
	height: 169px;
	background: url(../images/BHB.png) no-repeat center;
	position: absolute;
	left: -81px;
	background-size: contain;
	top: -11px;
	z-index: -1
}
.last-block__btn {
	display: block;
	background: -o-linear-gradient(top, #fdd508 0, #fcaa07 100%);
	background: linear-gradient(180deg, #fdd508 0, #fcaa07 100%);
	border: 2px solid #c69004;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	border-radius: 15px;
	font-style: normal;
	font-weight: 700;
	text-align: center;
	letter-spacing: .02em;
	font-size: 24px;
	text-decoration: none;
	color: #000;
	line-height: 74px
}
button {
	outline: 0
}
.last-block__btn:hover {
	/* background: -webkit-gradient(linear, left top, right top, color-stop(100%, #fcaa07), color-stop(0, #fdd508)); */
	background: -o-linear-gradient(left, #fcaa07 100%, #fdd508 0);
	background: linear-gradient(90deg, #fcaa07 100%, #fdd508 0);
	-webkit-transition: all 2.2s;
	-o-transition: all 2.2s;
	transition: all 2.2s
}
.last-block__btn span {
	position: relative;
	display: block
}
.last-block__btn span:before {
	content: "";
	display: block;
	width: 12px;
	height: 15px;
	background: url(../images/arrow-btn.png) no-repeat center;
	position: absolute;
	background-size: contain;
	left: 8px;
	top: 29px
}
.logo-f {
	display: block;
	margin: 5px auto 0
}
.website-secure {
	display: block;
	margin: 20px auto;
}
.soc-list {
	max-width: 411px;
	display: block;
	margin: 0 auto 20px !important;
}
.soc-list li {
	float: left;
	width: 50%;
	display: table;
	text-align: center;
	height: 51px;
	position: relative
}
.soc-list li img {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%)
}
.proLand-footer {
	padding-bottom: 50px;
	padding-top: 30px;
}
.proLand-footer p {
	font-size:.75rem;
	text-align: center;
	line-height: 1.4;
	margin-bottom: 1.25rem
}
.proLand-footer p + p{
    padding: 20px 0 0;
}
.terms-links {
	text-align: center
}
.terms-links li {
	display: inline-block
}
.terms-links li a {
	color: #000!important;
	font-size:.75rem;
	text-decoration: none
}

.slick-slide img {
	display: block;
	max-width: 350px;
	margin: 0 auto
}

.custom-social-proof {
	position: fixed;
	bottom: 20px;
	left: 20px;
	z-index: 9999999999999!important;
	font-family: 'Open Sans', sans-serif;
	display: none;
}
.custom-notification {
	width: 320px;
	border: 0;
	text-align: left;
	z-index: 99999;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	font-weight: 400;
	border-radius: 6px;
	padding-bottom: 7px;
	-webkit-box-shadow: 2px 2px 10px 2px hsla(0,4%,4%,.2);
	box-shadow: 2px 2px 10px 2px hsla(0,4%,4%,.2);
	background-color: #fff;
	position: relative;
	cursor: pointer
}
.custom-notification-container {
	display: block!important;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	max-width: 320px;
	width: 100%;
	height: 80px
}
.custom-notification-image-wrapper {
	float: left;
	width: 80px;
	margin-top: 5px
}
.custom-notification-image-wrapper img {
	max-height: 75px;
	width: auto;
	display: block;
	margin: 0 auto 5px;
	overflow: hidden;
	border-radius: 6px 0 0 6px
}
.custom-notification-content-wrapper {
	margin: 0;
	height: 100%;
	color: gray;
	padding-left: 20px;
	padding-right: 20px;
	border-radius: 0 6px 6px 0;
	padding-bottom: 5px
}
.custom-notification-content {
	font-family: inherit!important;
	padding: 0!important;
	font-size: 14px;
	line-height: 16px;
	width: calc(100% - 70px);
	float: left;
	margin-left: 10px;
	padding-bottom: 5px;
	margin-top: 10px
}
.custom-notification-content small {
	margin-top: 3px!important;
	display: block!important;
	font-size: 12px!important;
	opacity: .8
}
.custom-close {
	position: absolute;
	top: 8px;
	right: 8px;
	height: 12px;
	width: 12px;
	cursor: pointer;
	-webkit-transition: .2s ease-in-out;
	-o-transition: .2s ease-in-out;
	transition: .2s ease-in-out;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
	opacity: 0
}
.custom-close:before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: gray;
	position: absolute;
	left: 0;
	top: 5px
}
.custom-close:after {
	content: "";
	display: block;
	height: 100%;
	width: 2px;
	background-color: gray;
	position: absolute;
	left: 5px;
	top: 0
}
.custom-close:hover .custom-close {
	opacity: 1
}
.custom-social-proof .custom-notification .custom-close::before {
	content: "";
	display: block;
	width: 100%;
	height: 2px;
	background-color: gray;
	position: absolute;
	left: 0;
	top: 5px
}
.custom-social-proof .custom-notification .custom-close::after {
	content: "";
	display: block;
	height: 100%;
	width: 2px;
	background-color: gray;
	position: absolute;
	left: 5px;
	top: 0
}
.custom-social-proof .custom-notification:hover .custom-close {
	opacity: 1
}

.heartbeat{
	-webkit-animation: heartbeat 5s ease 0 infinite normal;
	animation: heartbeat 5s ease 0 infinite normal;
	-webkit-transform: scale(.94);
	-ms-transform: scale(.94);
	transform: scale(.94);
	-webkit-font-smoothing: antialiased;
	font-smoothing: antialiased
}
@-webkit-keyframes heartbeat {
0% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
3% {
-webkit-transform:scale(1);
-ms-transform:scale(1);
transform:scale(1)
}
6% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
12% {
-webkit-transform:scale(1);
-ms-transform:scale(1);
transform:scale(1)
}
18% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
}
@keyframes heartbeat {
0% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
3% {
-webkit-transform:scale(1);
-ms-transform:scale(1);
transform:scale(1)
}
6% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
12% {
-webkit-transform:scale(1);
-ms-transform:scale(1);
transform:scale(1)
}
18% {
-webkit-transform:scale(.94);
-ms-transform:scale(.94);
transform:scale(.94)
}
}

/*---------------------------
LOADING BAR
--------------------------*/
.popup-loading-wrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 1100;
	background: rgba(0, 0, 0, 0.95);
	text-align:center;
	}
.popup p {margin: 0}
.popup-loading-wrapper .popup {
	min-width: 300px;
	color: #fff;
	font-size: 14px;
	font-weight: 600;
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
	}
.popup-loading-wrapper .popup h2 {
	width: 300px;
	font-size: 32px;
	color: #fff;
	line-height: 36px;
	padding: 7px 0 22px;
	font-family: 'Open Sans', sans-serif;
	}
.popup-loading-wrapper .popup h3 {
	line-height: normal;
	padding: 0 0 24px;
	color: #fff
	}
.popup-loading-wrapper .popup p {
	opacity: .5;
	font-size: 18px;
	padding: 8px 0 3px;
	font-family: 'Open Sans', sans-serif;
	}
.popup-loading-wrapper .popup .product-image {
	width: 206px;
	height: 207px;
	margin: 0 auto 12px;
	position: relative;
	background: url(../images/product.png) no-repeat center center;
	background-size: contain;
	}
.popup-loading-wrapper .popup .loading-image {
    width: 70px;
    height: 70px;
    margin: auto;
    -webkit-animation: spin 3s linear infinite;
    -moz-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
	}

@-moz-keyframes spin {
100% {-moz-transform: rotate(360deg)}
}

@-webkit-keyframes spin {
100% {-webkit-transform: rotate(360deg)}
}

@keyframes spin {
100% {-webkit-transform: rotate(360deg);transform: rotate(360deg)}
}

.soc-list {
    margin-left: auto;
    margin-right: auto;
}

.fixed-btn {
    position: fixed;
    bottom: 15px;
    left: 0;
    width: 100%;
    z-index: 21;
}
.fixed-btn a {
    background-color: #5fbb49;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-image: -o-linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
    /* background-image: -webkit-gradient(linear, left top, left bottom, from(#a3cc2c), to(#5fbb49))!important; */
    background-image: linear-gradient(top, #a3cc2c 0, #5fbb49 100%)!important;
    color: #fff;
    border-radius: 10px;
    position: relative;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    display: block;
    margin: 0 15px;
    text-align: center;
    font-size: 21px;
    line-height: 45px;
    text-decoration: none;
}
.fixed-btn a:hover,
.fixed-btn a:focus{
	color: #fff;
}

.soc-list{
	padding-left: 0;
}


/* Responsive  */

@media screen and (max-width: 1199.5px){
	.first-section__info h1{
		font-size: 80px;
	}
	.first-section__info h2{
		font-size: 40px;
	}
	.first-section__info h3{
		font-size: 30px;
		line-height: 35px;
	}
	.first-section__info ul li{
		font-size: 25px;
	}
	.form-title {
		font-size: 25px;
	}
}

@media screen and (max-width: 1024.5px){
	.first-section__bottle:after{
		transform: scale(0.8);
	}
	.first-section__bottle:before {
		transform: scale(0.7);
	}
}

@media screen and (max-width: 991.5px){
	.first-section__bottle{
		bottom: 22px;
		right: 70px;
		max-width: 130px;
	}
	.header__btn, .diet-header__btn{
		width: 210px;
		font-size: 18px;
	}
	.header__info_side span, .diet-header__info_side span{
		font-size: 20px;
	}
	.header__info, .diet-header__info{
		margin-top: 10px;
	}
	.diet-header__info_side p span,
	.diet-header__info_side p{
		font-size: 12px;
	}
	.why-is__side h2,
	.how-use__titleMain{
		font-size: 2.1rem;
	}
	.last-block__block .last-block_left h2{
		font-size: 36px;
	}
	.slide__item_right > div {
		padding-left: 30px;
	}
	.slide__item_right p{
		margin-top: 30px;
	}
	.diet-header__info_side{
		width: calc(100% - 220px);
		margin-right: 10px;
	}
	.science__title {
		font-size: 35px;
	}
	.science__subTitle {
		font-size: 30px;
	}
	.d-mobile.arrow-img {
		max-width: 230px;
		margin-bottom: 15px;
	}
	.last-block__block .last-block_left ul{
		margin-bottom: 15px;
	}
	.last-block__btn{
		font-size: 20px;
	}
}

@media screen and (max-width: 767.5px){
	.diet-page .container {
		max-width: 711px !important;
		width: 100% !important;
	}
	.diet-header {
		background: #253b88;
		padding-bottom: 10px;
		position: relative;
		width: 100%;
		text-align: center;
		z-index: 22;
	}
	.diet-header:after {
		content: "";
		position: absolute;
		display: block;
		width: 197px;
		height: 24px;
		/* background: url(../images/arrow-head.png) no-repeat; */
		background-size: contain;
	
		bottom: -24px;
		left: 50%;
		margin-left: -100px;
	}
	.diet-header__warning {
		font-size: 12px;
		letter-spacing: 0.5px;
		line-height: 1.3;
		padding: 10px 10px;
	}
	.diet-header__warning .container{
		max-width: 551px!important;
	}
	.diet-header:before{
		display: none;
	}
	
	.diet-header__info{
		display: none;
	}
	.first-section .first-section__side:last-child {
		/* display: none; */
	}
	.first-section__info {
		padding-top: 11px;
		position: relative;
	}
	.first-section {
		padding-top: 0px;
		background: url(../images/model.jpg) no-repeat 105% top;
		background-size: 321px auto;
		padding-bottom: 45px;
		overflow: hidden;
	}
	.first-section .first-section__side {
		width: 100%;
		float: none;
	}
	.first-section .first-section__side:last-child{
		width: 100%;
		margin:150px 0 0;;
	}
	.first-section__info h1 {
		font-size: 55px;
		line-height: 73px;
	}
	.first-section__info h2 {
		font-size: 30px;
		line-height: 40px;
		margin-bottom: 0;
	}
	.first-section__info h3 {
		max-width: 300px;
	}
	.first-section__info ul li {
		font-size: 22px;
		line-height: 26px;
	}
	.first-section__info ul li:before {
		top: 8px;
	}
	/* .first-section__bottle {
		bottom: 0;
		right: 0;
		max-width: 130px;
		position: relative;
		margin: 0 auto 30px;
		display: block;
	} */
	.first-section__bottle{
		position: absolute;
		left: 308px;
		bottom: -104px;
		right: auto;
		max-width: 154px;
	}
	.first-section__bottle:before ,
	.first-section__bottle:after{		
		opacity: 0;
	}
	.first-section__bottle:before {
		opacity: 1;
		top: -60px;
		left: -20px	;
	}
	.first-section .first-section__side_in .arrow-img{
		display: none;
	}

	

	.why-is{
		padding: 0;
	}
	.why-is__side{
		float: none;
		width: 100%;
		width: auto;
		padding: 0 15px;
	}
	.why-is__side:first-child {
		text-align: center;
		width: 100%;
		padding: 0;
	}
	.why-is__side h2 {
		font-size: 24px;
		margin-top: 17px;
	}
	.why-is__side p{
		padding-top: 0;
	}
	.why-is__side h2 br:first-child{
		display: none;
	}
	.wy-btl{
		left: 150px;
	}
	.how-use__titleMain {
		font-size: 24px;
	}
	.science__title{
		font-size: 2.5rem;
	}
	.first-section__info img {
		padding-top: 0;
		margin-bottom: 5px;
	}
	.garante-list {
		display: block;
	}
	.first-section__info ul li{
		list-style: none;
	}
	.garante-list li {
		display: inline-block!important;
		float: left;
		padding: 0!important;
		list-style: none !important;
	}
	.garante-list li:before{
		display: none !important;
	}
	.garante-list {
		list-style-type: none;
		position: absolute;
		bottom: 0;
		left: -277px;
		transform: scale(.8, .8);
		-ms-transform: scale(.8, .8);
		-webkit-transform: scale(.8, .8);
	}
	.garante-item_1 {
		background: url(../images/sprite1.png) 0 -335px no-repeat;
		height: 97px;
		width: 117px;
	}
	.garante-item_2 {
		background: url(../images/sprite1.png) 0 -237px no-repeat;
		height: 98px;
		width: 119px;
	}
	.garante-item_3 {
		background: url(../images/sprite1.png) 0 -432px no-repeat;
		height: 96px;
		width: 116px;
	}
	.security-icons.d-mobile{
		display: none;
	}

	.how-use__item img{
		max-width: 100%;
	}
	.carousel-indicators li {
		border: none !important;
		padding: 0 !important;
	}
	.how-use .carousel-indicators {
		bottom: -25px;
	}
	.science__subTitle, .science__title {
		font-size: 30px;
	}
	.science__img {
		position: relative;
		margin-top: 50px;
	}
	.review h2 {
		font-size: 30px;
	}
	.slide__item_right > div {
		padding-left: 0;
	}
	.slide__item_right span.prod-name {
		display: inline-block;
	}
	.slide__item_right p{
		margin-top: 15px;
	}
	.single-item ol.carousel-indicators {
		bottom: -45px;
		width: 100%;
		margin: 0 auto;
	}
	.last-block__block .last-block_right{
		display: none;
	}
	.last-block__block .last-block_left {
		width: 55%;
	}
	.last-block__block .last-block_middle {
		width: 45%;
	}
	.last-block__btn {
		font-size: 14px;
		line-height: 35px;
		border-radius: 5px;
	}
	.last-block__btn span:before{
		display: none;
	}
	.last-block__block .last-block_left h2 {
		font-size: 25px;
	}
	.last-block-bottle {
		max-width: 175px;
	}
	.last-block-bottle:before{
		width: 60px;
		height: 60px;
		left: auto;
	}
}

@media screen and (max-width: 590.5px){
	.first-section {
		background: url(../images/model.jpg) no-repeat 126% top;
		background-size: 323px auto;
		padding-bottom: 45px;
	}
	.first-section__info h1 {
		font-size: 77px;
		max-width: 243px;
		line-height: 60px;
		margin-top: 0;
	}
	.first-section__info h2 {
		font-size: 31px;
		line-height: 33px;
		max-width: 251px;
		margin-top: 12px;
		margin-bottom: 14px;
	}
	.first-section__info h3 {
		max-width: 240px;
		font-size: 24px;
		line-height: 25px;
	}
	.first-section__info ul li {
		font-size: 17px;
		line-height: 15px;
	}
	.first-section__info ul li:before {
		top: 2px;
	}
	.first-section__bottle {
		left: 246px;
		bottom: -104px;
	}
}

@media screen and (max-width: 480.5px){
	.diet-header__logo {
		margin-top: 15px;
	}
	.diet-header:after{
		bottom: -15px;
	}
	.first-section {
		background: url(../images/model.jpg) no-repeat 163px top;
		background-size: 335px auto;
	}
	.first-section__info h1 {
		font-size: 63px;
		max-width: 243px;
	}
	.first-section__info h2 {
		font-size: 27px;
		line-height: 29px;
		max-width: 251px;
		margin-top: 4px;
		margin-bottom: 9px;
	}
	.first-section__info h3 {
		max-width: 198px;
		font-size: 20px;
		line-height: 21px;
		margin-top: 9px;
	}
	.first-section__bottle {
		max-width: 124px;
		left: 235px;
		bottom: -104px;
	}
	.garante-list {
		list-style-type: none;
		position: absolute;
		bottom: 0;
		left: -277px;
		transform: scale(.6, .6);
		-ms-transform: scale(.6, .6);
		-webkit-transform: scale(.6, .6);
	}
	.last-block__block .last-block_left {
		width: 100%;
	}
	.last-block__block .last-block_middle {
		width: 100%;
	}
	.last-block__block .last-block_left ul li{
		width: 33%;
	}
	.last-block__block .last-block_left ul li:last-child{
		display: none;
	}
}

@media screen and (max-width: 380.5px){
	.first-section {
		background: url(../images/model.jpg) no-repeat 129px top;
		background-size: 346px auto;
	}
	.first-section__info h2 {
		font-size: 24px;
		line-height: 29px;
		max-width: 222px;
		margin-top: 2px;
		margin-bottom: 3px;
	}
	.first-section__info h3 {
		max-width: 162px;
		font-size: 20px;
		line-height: 24px;
		margin-top: 9px;
	}
	.first-section__info ul li {
		font-size: 14px;
		line-height: 12px;
	}
	.first-section__bottle {
		max-width: 124px;
		left: 225px;
		bottom: -87px;
	}
	.first-section__bottle img {
		position: relative;
		top: -30px;
	}
	.wy-btl{
		width: 145px;
	}
}


/* Mobile Checkout  */
.mobileCheckout{
	font-size: 12pt;
    line-height: 1.5;
	font-family: 'Open Sans', sans-serif;
	overflow: hidden;
	padding: 45px 0 45px;
}
.mobileCheckout .container {
    max-width: 1000px;
    display: block;
    margin: 0 auto;
}
.m-check-left {
    width: 66.6%;
    float: left;
}
.m-check-right {
    width: 33.4%;
    padding: 0 15px;
    float: left;
}

#top-1 {
    display: block;
    padding-top: 2px;
}
.breadcrumbs__list {
    margin-left: -14px;
    background: #eee;
}
.breadcrumbs__list:after, .breadcrumbs__list:before {
    content: "";
    display: block;
    clear: both;
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.breadcrumbs__item {
    width: 31.98%;
    float: left;
    background: #dcdcdc;
    list-style-type: none!important;
    text-align: center;
    position: relative;
}
.breadcrumbs__item_active {
    background: #253b88;
}
.breadcrumbs__item:first-child {
    margin-right: 0;
}
.breadcrumbs__item:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    right: -18px;
    height: 100%;
    top: 0;
    background: url(../images/grey.png) no-repeat center;
    background-size: cover;
    z-index: 2;
}
.breadcrumbs__item_active:before {
    background: url(../images/purple.png) no-repeat center;
    background-size: contain;
}
.breadcrumbs__item span {
    font-weight: 600;
    font-size: 11px;
    line-height: 34px;
    height: 34px;
    margin-left: 27px;
    position: relative;
    right: -6px;
    display: inline-block;
    z-index: 4;
    letter-spacing: -.5px;
    -webkit-text-size-adjust: 100%;
    color: #565656;
}
.breadcrumbs__item_active span {
    color: #fff;
}
.breadcrumbs__item span:before {
    content: "1";
    position: absolute;
    width: 17px;
    height: 17px;
    border-radius: 50%;
    text-align: center;
    color: #565656;
    background: #fff;
    -webkit-text-size-adjust: 100%;
    left: -19px;
    line-height: 18.9px;
    font-size: 13px;
    top: 8px;
}
.breadcrumbs__item_2 span:before {
    content: "2";
}
.breadcrumbs__item_3 span:before {
    content: "3";
}
.breadcrumbs__item_active span:before {
    color: #7f389e;
}

.mob-check-form label, 
.billing-form .form-holder > span{
    font-family: arial, sans-serif !important;
    font-size: 12pt !important;
    line-height: 1.5 !important;
    color: #000 !important;
    font-weight: 300;
}

.mob-check-form input[type=email], 
.mob-check-form input[type=number], 
.mob-check-form input[type=password], 
.mob-check-form input[type=tel], 
.mob-check-form input[type=text], 
.mob-check-form input[type=url] {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    padding-left: 5px;
    margin-right: -5px;
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 17px;
    border: 1px solid #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
	box-shadow: none;
}
.mob-check-form select ,
.diet-page .mob-check-form select {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    margin: 0 5px 10px 0;
	padding: 0;
    padding-left: 5px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 17px;
    border: 1px solid #000;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
	box-shadow: none;
}


.mob-check-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	font-size: 17px;
	font-family: 'Open Sans', sans-serif;
}
  
.mob-check-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
	font-size: 17px;
	font-family: 'Open Sans', sans-serif;
}
  
.mob-check-form input::-ms-input-placeholder { /* Microsoft Edge */
	font-size: 17px;
	font-family: 'Open Sans', sans-serif;
}

.mob-check-form .select2.select2-container {
    /* border: 1px solid #999 !important; */
    height: 40px;
    margin-bottom: 15px !important;
}
.mob-check-form .select2-container .select2-selection--single{
	height: 40px;
    color: #222222;
    box-shadow: none;
}
.mob-check-form .select2-container--default .select2-selection--single .select2-selection__rendered {
	line-height: 40px;
    color: #222222;
    font-size: 14px;
    border: 1px solid #000!important;
    border-radius: 5px;
}
.mob-check-form  span.select2-selection {
    padding-top: 0;
}

.mob-check-form .select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 8px;
}

.form-partial button[type=submit],
.partialsubmitbutton {
    -webkit-appearance: none;
    -moz-appearance: none;
    display: block;
    cursor: pointer;
    margin-top: 25px;
    font-size: 2em;
    line-height: 3em;
    font-weight: 700;
    height: 3em;
    width: 100%;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    font-family: arial, helvetica, sans-serif;
    text-decoration: none;
    display: inline-block;
    text-shadow: -1px -1px 0 rgb(0 0 0 / 30%);
    font-weight: 700;
    color: #fff;
    background: #253b88;
    outline: none;
    text-align: center;
}
.qualify-btn {
	border: none !important;
    height: auto !important;
    padding: 0 !important;
    box-shadow: none !important;
}
.linebreak {
    height: 4px;
    background-color: #ececec;
}


.package:after, .package:before {
	content: "";
	display: block;
	clear: both
}
.package__left {
	width: 43%;
	float: left;
	position: relative;
	margin-bottom: 3px;
	font-weight: 700
}
.package__right {
	float: left;
	width: 57%;
	margin-bottom: 3px;
	box-sizing: border-box
}
.bottle-block {
	position: relative;
	margin-right: -5px;
	padding-top: 5px;
	padding-left: 11px
}
.package1 {
	position: relative
}
.package2 .bottle-block {
	padding-left: 0;
	padding-top: 0
}
.package2 .package__bottle {
	height: 82px
}
.package3 .bottle-block {
	padding-left: 0;
	padding-top: 0;
	padding-right: 8px
}
.package3 .package__bottle {
	height: 82px
}
.package__name {
	color: #000;
	font-family: "Arial Black", Gadget, sans-serif;
	font-size: 16px;
	display: block;
	text-transform: uppercase;
	margin-bottom: 2px
}
.package {
	margin: 0 10px
}
.package__coll {
	line-height: 13px;
	font-size: 11px;
	color: #7f37a8;
	display: block;
	font-weight: 500;
	font-weight: 700;
	letter-spacing: .05em
}
.package__free {
	color: #000;
	letter-spacing: .01em;
	font-style: italic;
	display: block;
	font-size: 11px;
	line-height: 11px
}
.package__some {
	font-size: 9px;
	line-height: 7px;
	display: block;
	margin-bottom: 3px
}
.package__right:after, .package__right:before {
	content: "";
	display: block;
	clear: both
}
.package__bottle {
	width: 50%;
	float: left;
	height: 80px
}
.package {
	font-family: Arial, Helvetica, sans-serif;
	max-width: 440px;
	display: block;
	margin: 0 auto;
	padding: 3px 10px 4px;
	position: relative
}
.package_active {
	background-color: #f8f187
}
.package-btn {
	background: #253b88;
	border-radius: 2px;
	text-transform: uppercase;
	line-height: 46x;
	height: 50px;
	text-align: center;
	float: left;
	display: block;
	width: 100%;
	color: #fff;
	box-sizing: border-box;
	font-size: 18px
}
.package-btn span {
	font-size: 28px
}
.package__bottle {
	position: relative;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}
.packWrapper .package-images__item {
    float: left;
    width: 50%;
    height: 85px;
    position: relative;
}
.packWrapper .package-images__item .prod-btl1, 
.packWrapper .prod-btl2, 
.packWrapper .prod-btl3, 
.packWrapper .prod-btl4, 
.packWrapper .prod-btl5{
    position: absolute;
    top: 10px;
    left: 37%;
    width: 34%;
    z-index: 4;	
}
.packWrapper .prod-btl2{
    width: 32%;
    left: 59%;
    top: 3px;
    z-index: 2;
}
.packWrapper .prod-btl3{
    top: 3px;
    left: 17%;
    width: 32%;
    z-index: 2;
}
.packWrapper .prod-btl4{
	left: 26px;
    top: 3px;
    width: 32%;
	}
.packWrapper .prod-btl5{
	left:41%;
	}
.packWrapper .chk-plus{
    position: absolute;
    top: 34px;
    left: 58%;
    z-index: 9;
    width: 7%;	
}
.package__price, .package__price p {
	font-size: 20px;
	color: #359401;
	font-weight: 700;
	display: block;
	letter-spacing: -.01em
}
.package__price span {
	font-weight: 400;
	font-size: 9px;
	margin-left: 1px
}
.package__retail {
	color: #676654;
	font-size: 8px;
	display: inline-block;
	font-weight: 400;
	margin-right: 7px
}
.package2 .package__retail {
	margin-right: 13px
}
.package__stock {
	color: #777;
	display: block;
	font-size: 10px;
	margin-top: 3px;
	font-weight: 400;
	letter-spacing: -.01em
}
.retail-block {
	line-height: 12px;
	text-align: center;
	position: relative;
	top: 0;
	clear:both;
}
.shipping-block {
	text-transform: uppercase;
	font-size: 9px;
	margin-left: -4px;
	font-family: Georgia, "Georgia, Times, sans-serif";
	letter-spacing: -.03em
}
.shipping-block span {
	vertical-align: middle;
	font-weight: 700
}
.shipping-block:before {
	content: "";
	display: inline-block;
	width: 12px;
	height: 12px;
	background: url(../images/box-icon.svg) no-repeat;
	background-size: contain;
	margin-right: 3px;
	margin-top: -2px;
	vertical-align: middle
}

.save-label {
    width: 50px;
    height: 49px;
    background: url(../images/save-label.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;
    color: #fff;
    font-size: 7px;
    line-height: 14px;
    position: absolute;
    top: 21px;
    right: -3px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 9px 6px 0 0;
}
.save-label span {
    display: block;
    font-size: 19px;
    position: relative;
}
.save-block {
    width: 45px;
    height: 45px;
    background: url(../images/save-star.png) no-repeat;
    background-size: contain;
    position: absolute;
    left: -25px;
    top: 28px;
    border-radius: 50%;
    z-index: 3;
    color: #5c5c5c;
    font-size: 10px;
    line-height: 10px;
}
.save-block__title {
    display: block;
    text-align: center;
    margin-top: 13px;
}
.save-block__price {
    display: block;
    text-align: center;
    font-size: 8px;
    font-weight: 400;
}
.best-choise {
    position: absolute;
    top: 42px;
    right: 0;
    text-align: center;
    color: #fff;
    font-size: 8px;
    line-height: 8px;
    padding: 3px;
    background: url(../images/red-ribbon.png) no-repeat;
    background-size: 100% 100%;
    width: 45px;
    height: 22px;
    text-transform: uppercase;
    font-family: "Times New Roman", serif;
    font-style: italic;
    box-sizing: border-box;
    z-index: 10;
}
@keyframes btn_anim {
	from {
	-ms-transform:scale(.9, .9);
	-webkit-transform:scale(.9, .9);
	transform:scale(.9, .9);
	opacity:.5
	}
	to {
		-ms-transform: scale(1.02, 1.02);
		-webkit-transform: scale(1.02, 1.02);
		transform: scale(1.02, 1.02);
		opacity: 1
	}
}
.btn_pulse {
	animation-name: btn_anim;
	animation-duration: 1s;
	animation-direction: alternate;
	animation-iteration-count: infinite
}
.partialform button {
    color: #fff;
    background: #253b88;
    outline: none;
}

.payment_as_shipping_label input {
	margin: -2px 5px 0 0 !important;
    width: auto !important;
    height: auto !important;
    display: inline-block !important;
}

.cards-list {
    margin: 15px 20px 0;
    text-align: center;
}
form#order_form {
    padding: 20px 0 0 0;
}
.cards-list img {
    display: inline-block;
    height: 30px;
    width: auto;
    margin: 0 3px;
    vertical-align: middle;
}

#shortcheckoutcontainer #cvv, 
#shortcheckoutcontainer #expire_m, 
#shortcheckoutcontainer #expire_y,
.checkout-form-wrapper select#mm_field_cc_exp_month,
.checkout-form-wrapper select#mm_field_cc_exp_year {
    width: 48%;
    float: left;
}
.left {
    float: left;
}
.checkout-form-wrapper .cvv-link{
	margin-top: 0;
}
.cvv-link a {
    margin: 10px 0 0 17px!important;
    font-family: 'Open Sans', sans-serif;
    color: rgb(0, 0, 238) !important;
    font-weight: 600;
    font-size: 15px !important;
    text-decoration: underline !important;
}
.checkout-form-wrapper input#cform-submit-btn {
    border: none !important;
    box-shadow: none !important;
    height: auto !important;
}

.paymentform .cvv-link{
	margin: 0%;
	width: unset;
}
.paymentform .cvv-link a{
	color: rgb(0, 0, 238);
	cursor: pointer;

}
.paymentform input[type="checkbox"] {
    -webkit-appearance: button !important;
    height: 15px !important;
    width: 15px !important;
    position: relative;
    top: 2px;
}

.cards-list span {
    color: #6f6c6c;
    font-size: 15px;
    line-height: 25px;
    display: block;
    font-weight: 700;
}
.diet-page label.spl-checkbox ,
.finalOrder .frmCheckElemts .spl-checkbox{
    font-size: 12px !important;
	display: block;
}

div#shortcheckoutcontainer {
    padding: 20px 0 0 0;
}
.finalOrder label.payment_as_shipping_label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
}


.checkout-form-wrapper .mm-button {
	padding: 0;
    margin: 0;
    outline: none;
    animation-name: btn_anim;
    animation-duration: 1s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    /* background: url(../images/RushButton.jpg); */
    width: 100% !important;
	height: 60px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: none;
    box-shadow: none;
    color: transparent !important;
    text-shadow: none;
}

.err_msg {
	font-size: 13px;
    color: #ff0000;
    font-weight: 500;
}
.form-partial button[type=submit]:focus, .partialsubmitbutton:focus {
    color: #ffffffdb;
}
.form-holder{
	position: relative;
}
.formErrorContent {
    width: 100%;
    background: #ee0101;
    position: relative;
    color: #fff;
    min-width: 120px;
    font-size: 11px;
    border: 2px solid #ddd;
    box-shadow: 0 0 6px #000;
    -moz-box-shadow: 0 0 6px #000;
    -webkit-box-shadow: 0 0 6px #000;
    -o-box-shadow: 0 0 6px #000;
    padding: 4px 10px 4px 10px;
	position: absolute;
    z-index: 9;
	top: -30px;
}
.formErrorContent:before {
    position: absolute;
    content: "";
    border-top: 7px solid #ee0101;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
	bottom: -7px;
}

.loading_sidebar::after {
	position: absolute;
	top: 50vh;
	left: 50%;
}


/* Responsive  */
@media screen and (max-width: 1199.5px) and (min-width: 992px){
	.first-section__bottle{
		max-width: 200px;
		right: 75px;
		bottom: 55px;
	}
	
	.first-section__bottle:before{
		left: 15px;
	}
	.first-section__bottle:after{
		transform: scale(0.8);
		top: -80px;
	}
}
@media screen and (max-width: 992.5px){
	.why-is__side h2, .how-use__titleMain {
		font-size: 1.7rem;
	}
}
@media screen and (max-width: 767.5px){
	.m-check-left {
		width: 100%;
		float: none;
	}
	.m-check-right {
		width: 100%;
	}
	.mob-check-form .select2.select2-container{
		width: 100% !important;
		max-width: 100% !important;
	}
	.page-id-3600 .packWrapper .prod-btl3{
		width: 55px;
	}
	.page-id-3600 .packWrapper .package-images__item .prod-btl1, 
	.page-id-3600 .packWrapper .prod-btl2, 
	.page-id-3600 .packWrapper .prod-btl3, 
	.page-id-3600 .packWrapper .prod-btl4, 
	.page-id-3600 .packWrapper .prod-btl5{
		width: 55px;
	}
	.page-id-3600 .packWrapper .prod-btl4{
		width: 55px;
	}
	.page-id-3600 .packWrapper .prod-btl2{
		width: 55px;
	}
	.first-section__info ul.workLists{
		margin-bottom: 30px;
	}

}

@media (max-width:400px) {
	.first-section__bottle:before {
		opacity: 1;
		top: -60px;
		left: -40px;
		transform: scale(0.7);
	}
}

@media (max-height:400px) {
	.package__stock {
		font-size: 9px
	}
	.shipping-block {
		font-size: .48em
	}
}