.thankYou-page{
    padding: 50px 0;
}

.thankYou-page h3, .thankYou-page h3 a{
    font-size: 26px !important;
    color: #18aebf !important;
    text-align: center;
    margin-bottom: 30px;    
    letter-spacing: 0;
    font-family: "Oswald", sans-serif;
    font-weight: 700;
}

.thankYou-cont .thankYou-card{
    font-size: 17px;
    border: 1px solid #ccc;
    padding: 15px;
    border-radius: 10px;
    margin: 15px auto;
}


.thankYou-card  strong {
    color: #18aebf;
    border-bottom: 1px solid #ccc;
    display: inline-block;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.unit_price_th,.item_price_th, .item_status_th,
.unit_price_th , .item_price_th, .item_status_th{
    text-align: center;
}

@media only screen and (max-width: 767.9px){
    .thankYou-page h3, .thankYou-page h3 a {
        font-size: 20px !important;
        line-height: 30px;
    }
}