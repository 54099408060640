@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');


.faceCream-landing-page{
    background-color: #fff;
}

.faceCream-landing-page p:last-child{
    margin-bottom: 0;
}

.topAnnoncment {
    background: #ed145b none repeat scroll 0 0;
    color: #ffffff;
    display: block;
    line-height: normal;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    padding: 10px 20px;
    text-align: center;
}
.contactInfo {
    background: #333;
    color: #ccc;
    padding: 3px;
    text-align: center;
    font-size: 10px;
}
.contactInfop p{
    font-size: 10px;
}


.headerbg {
    background: url(../images/facecream/bg-desktop.jpg) top center no-repeat;
    min-height: 1018px;
}
img.Top_Logo {
    position: absolute;
    right: 50px;
    top: 30px;
    height: 90px;
    
}
.bannerLeft{
    position: relative;
    padding-top: 75px;
}
img.Step1_Product {
    top: 500px;
    left: -8%;
    width: 284px;
    position: absolute;
}
/* .bannerTextImg{
    padding-top: 100px;
} */
.grabber-img {
    width: 79%;
    margin-right: -25px;
    margin-bottom: 25px;
    float: right;
}
.bannerTextImg .mobile{
    margin-bottom: 25px;
    display: none;
}
.viraltext {
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 25px;
    font-weight: 500;
    color: #050505;
    padding: 25px 75px !important;
    display: block;
}
.viraltextm {
    display: none;
}

.viraltext .star{
    margin-bottom: 15px;
    object-fit: contain;
}

.formSeals {
    padding: 15px 0 15px 0;
}
.formBox {
    background-color: #fff;
    border-radius: 20px;
    border: 4px solid #0699ee;
    overflow: hidden;
}
.formTop {
    background: #0699ee;
    padding: 25px;
    color: #fff;
    font-size: 30px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    text-align: center;
    line-height: 35px;
    overflow: visible;
}
.formTop img {
    float: right;
    position: absolute;
    top: 100px;
    right: -95px;
    padding: 0;
    margin: 0;
}
.stripe {
    background-color: #666;
    font-family: 'Fira Sans', sans-serif;
    text-transform: uppercase;
    padding: 5px 8px;
    font-size: 13px;
    color: #fff;
    text-align: center;
}
.formBody {
    padding: 30px 30px 25px 30px;
}


.prospectForm .fields{
    margin-bottom: 15px;
    position: relative;
}
.prospectForm .form-control{
    background: #FFF;
    border: 1px solid #a7abad;
    color: #808181;
    height: 35px;
    line-height: 22px;
    width: 100%;
    font-size: 14px;
    padding: 3px 8px;
    outline: 0;
    font-family: Arial,sans-serif;
    font-weight: 400;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    border-radius: 0;
}
.secTxt {
    font-size: 12px;
    color: #666;
    text-align: center;
}

.secTxt .fa-lock {
    height: 14px;
    width: 14px;
    display: inline-block;
    position: relative;
    top: 2px;
    background: url(../images/facecream/lock-img-yellow.svg) top center no-repeat;
    margin-top: 10px;
}
.facecreamCheckout .checkboxLabel input[type="checkbox"]{
    top: 3px !important;
}
.pt_30{
    padding-top: 30px;
}
.revLeft{
    padding: 30px;
}
.revolutionary-section h2 {
    font-size: 34px;
    font-family: 'Fira Sans', sans-serif;
    color: #e70464;
    text-transform: uppercase;
    font-weight: 700;
}

.revolutionary-section .highlight {
    color: #04a8f5;
}
img.Step1_Product-col-sm-5 {
    width: 231px;
    top: 148px;
    left: 142px;
}
.revRight{
    position: relative;
}
img.Step1_Product-col-sm-5 {
    width: 231px;
    top: 148px;
    left: 142px;
    position: absolute;
}
.mobile {
    display: none;
}

/* The Proof Id  */
.theproofD {
    background: url(../images/facecream/flowerwoman-bg.jpg) top center no-repeat;
    min-height: 1013px;
}
.theproofD h1 {
    font-family: "Fira Sans", sans-serif;
    font-size: 37px;
    font-weight: 700;
    color: #fff;
    margin: 15px 0;
    text-align: center;
}
.theproofD-inner{
    position: relative;
}
.theproofD-inner img.Step1_Product-viral {
    top: 655px;
    left: -502px;
    position: absolute;
}
.theproofD p {
    font-size: 12px;
    color: #363535;
    font-weight: bold;
}
.the-proof{
    margin-top: 107px;
}


/* How It Works  */

.mont60 {
    font-family: "Fira Sans", sans-serif;
    color: #e70464;
    font-size: 60px;
    font-weight: 300;
}
.os28 {
    font: 28px "Open Sans", sans-serif;
    color: #333;
    line-height: normal;
    font-weight: 300;
}
.blue {
    color: #1496c6;
}
.how_it_work h2{
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 20px;
}

.how_it_work .orange {
    color: #e70464;
}

.how_it_work h4{
    font-family: "Oswald", sans-serif;
    font-weight: 700;
    font-size: 18px;
}
.content {
    color: #666;
}

.dark {
    color: #333;
}
.visibleResult{
    padding: 45px 0;
}
.os33 {
    font-size: 33px;
    text-transform: uppercase;
    font-family: "Oswald", sans-serif;
    font-weight: 300;
}
.super {
    font-weight: 800;
}
.orange{
    color: #e70464 !important;
}


.ketpoia-ketosis-banner {
    background: url(../images/facecream/featurebg.jpg) top center no-repeat;
    min-height: 845px;
    padding-top: 320px;
}
.ketpoia-ketosis-banner .container h2 {
    font-family: "Open Sans", sans-serif;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-weight: 300;
}
img.Step1_Product-ketpoia {
    width: 300px;
    left: 554px;
}



.ketoImg-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}



.ketoImg-wrapper .product{
    position: relative;
}

img.Logo-Ketpoia {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: auto;
}

.ketoImg-wrapper .product img.flowerImg {
    position: absolute;
    bottom: -70px;
    left: 0;
    right: 0;
    margin: 0 auto;
}

.checkoutBtn-div {
    margin-top: 20px;
}
/* What You Get  */
.center-block {
    display: block;
    margin-right: auto;
    margin-left: auto;
}
.space {
    height: 40px;
    display: block;
}
img.Step1_Product-col-sm-offset-2-01, img.Step1_Product-col-sm-offset-2-02, img.Step1_Product-col-sm-offset-2-03 {
    top: 266px;
    width: 270px;
    position: absolute;
}
img.Step1_Product-col-sm-offset-2-01 {
    left: 135px;
}
img.Step1_Product-col-sm-offset-2-02 {
    left: 370px;
}
img.Step1_Product-col-sm-offset-2-03 {
    left: 246px;
}
.productTitle {
    padding-bottom: 10px;
    font-size: 32px;
    color: #06a5f4;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
}
.productSub {
    font-size: 18px;
    color: #000;
    padding-bottom: 10px;
}

.text-lists{
    padding-left: 0;
}
.text-lists li{
    list-style: none;
    color: #666;
}
.c2abtn {
    -webkit-border-radius: 15;
    -moz-border-radius: 15;
    border-radius: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 600;
    color: #ffffff;
    font-size: 34px;
    background: #e70464;
    padding: 15px 30px 15px 30px;
    text-decoration: none;
    border: none;
}
.c2abtn:hover {
    background: #f24d11;
    text-decoration: none;
}

.stayingKetosis {
    background: url(../images/facecream/simplebg.jpg) top center no-repeat;
    min-height: 847px;
    padding-top: 320px;
}
.stayingKetosis h3 {
    font-family: "Montserrat", sans-serif;
    color: #000;
    font-size: 45px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 700;
}
.stayingKetosis h2 {
    font-family: "Montserrat", sans-serif;
    color: #e70464;
    font-size: 125px;
    font-weight: 600;
    padding: 0;
    margin: 0;
    text-align: center;
}
.stayingKetosis h3 {
    font-family: "Montserrat", sans-serif;
    color: #000;
    font-size: 45px;
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 700;
}

.posRelative{
    position: relative;
    /* padding-top: 75px; */
}


.ketopiainfo .row{
    align-items: center;
}
.ketopiainfo .productImg {
    margin-top: -90px;
}
.ketopiainfo h2 {
    font-family: "Montserrat", sans-serif;
    color: #06a6f5;
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 700;
}
.ketopiainfo h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 26px;
    color: #06a6f5;
    font-weight: 700;
}
img.Step1_Product-col-sm-4 {
    width: 300px;
    left: 87px;
}

.productImg{
    position: relative;
    text-align: center;
    z-index: 1;
}

.productImg .flowerImg {
    width: 100px;
    height: auto;
    position: absolute;
    top: 40%;
    left: -30px;
    z-index: -1;
}

.allnatural {
    background: url(../images/facecream/feturebg2.jpg) top center no-repeat;
    min-height: 850px;
    padding-top: 258px;
}


.footerC2a {
    background: url(../images/facecream/footerbg_1.jpg) top center no-repeat;
    min-height: 1030px;
    padding-top: 157px;
}
.footerC2a .posRelative{
    padding-top: 75px;
}
.footerProductWrapper{
    position: absolute;
    width: 425px;
    left: -60px;
    right: 0;
    margin: 0 auto;
    top: 345px;
}

.footerProductWrapper img{
    margin: 0 auto;
    display: block;
    width: 180px;
}

.Step1_Product-footer-01,
.Step1_Product-footer-02,
.Step1_Product-footer-03 {
    position: absolute;
    top: 0;
}
.Step1_Product-footer-01{
    left: 0;
}

.Step1_Product-footer-02 {
    right: 0;
}
.Step1_Product-footer-03 {
    left: 0;
    right: 0;
}
.footer-logo {
    position: absolute;
    top: -25px;
    left: -115px;
    right: 0;
}

.facecream-footer {
    font: 12pt bold Arial,Helvetica,sans-serif;
    text-align: center;
    margin: 50px auto;
    color: #666;
}

.facecream-footer .copyright {
    text-align: center;
    clear: both;
    font-size: 12px;
    color: #8a8a8a;
}
.facecream-footer .footerlinks {
    display: block;
}

.facecream-footer .footerlinks a {
    color: #666;
    text-decoration: none;
    font-size: 12px;
}

.facecream-footer .footerlinks a:hover{
    color: rgb(138, 138, 138);
}

#footer_trial_disclaimer,
#footer_trial_disclaimer p{
    font-family: 'Open Sans', 'Arial', san-serif;
    color: #8a8a8a !important;
    font-size: 12px;
}

.facecream-footer .footerlinks a:not(:first-child){
    padding-left: 5px;
    display: inline-block;
}


/* Responsive  */
@media screen and (max-width: 1199.5px){
    .formTop img {
        width: 60px;
        height: auto;
        right: -45px;
    }
    img.Step1_Product{
        width: 225px;
    }
    img.Top_Logo {
        right: 30px;
        top: 20px;
    }
    .grabber-img{
        /* margin-top: 65px; */
    }
    img.Step1_Product {
        top: 435px;
        left: -4%;

    }
    .formBody {
        padding: 20px 15px 25px 15px;
    }
    .formTop {
        font-size: 20px;
        line-height: 30px;
        padding: 15px;
    }
    .viraltext{
        font-size: 20px;
    }
    .headerbg{
        min-height: 900px;
    }
    .theproofD h1 {
        font-size: 25px;
    }
    .theproofD-inner img.Step1_Product-viral {
        width: 335px;
        top: 480px;
        left: -430px;
    }
    .mont60{
        font-size: 45px;
    }
    .os28 {
        font-size: 20px;
    }
    img.Step1_Product-col-sm-offset-2-01, img.Step1_Product-col-sm-offset-2-02, img.Step1_Product-col-sm-offset-2-03{
        width: 200px;
    }
    img.Step1_Product-col-sm-offset-2-01 {
        left: 95px;
    }
    img.Step1_Product-col-sm-offset-2-03 {
        left: 215px;
    }
    img.Step1_Product-col-sm-offset-2-02 {
        left: 340px;
    }
    .productTitle{
        font-size: 25px;
    }
    .stayingKetosis h3{
        font-size: 30px;
    }
    .stayingKetosis h2{
        font-size: 85px;
    }
    .ketopiainfo h3{
        font-size: 22px;
    }
    .c2abtn{
        font-size: 24px;
    }
    .footerProductWrapper{
        width: 345px;
        top: 287px;
    }
    .footerProductWrapper img{
        width: 145px;
    }
    .footerC2a{
        min-height: 835px;
    }
    .footer_bottom {
        padding-top: 45px;
    }
}


@media screen and (max-width: 1024.5px){
    img.Step1_Product {
        top: 400px;
        left: -8%;
    }
}


@media screen and (max-width: 991.5px){
    .headerbg{
        background-position: 40% top;
    }
    .grabber-img {
        width: 515px;
        margin-right: 10px;
    }
    .viraltext{
        float: left;
    }
    .formTop img{
        right: 0;
        display: none;
    }
    img.Top_Logo {
        right: 75px;
    }
    img.Step1_Product-col-sm-5 {
        width: 190px;
        top: 175px;
        left: 55px;
    }
    img.Step1_Product {
        width: 220px;
        top: 480px;
        left: auto;
        right: 475px;
    }
    .theproofD-inner img.Step1_Product-viral {
        width: 280px;
        top: 445px;
        left: -385px;
    }
    img.Step1_Product-ketpoia {
        width: 225px;
    }
    img.Logo-Ketpoia{
        width: 200px;
    }
    .ketoImg-wrapper .product img.flowerImg{
        bottom: -45px;
        width: 100px;
    }
    img.Step1_Product-col-sm-offset-2-01, img.Step1_Product-col-sm-offset-2-02, img.Step1_Product-col-sm-offset-2-03 {
        width: 145px;
    }
    img.Step1_Product-col-sm-offset-2-01 {
        left: 60px;
    }
    img.Step1_Product-col-sm-offset-2-03 {
        left: 171px;
    }
    img.Step1_Product-col-sm-offset-2-02 {
        left: 285px;
    }
    .whatYouGet .space {
        display: none;
    }
    .whatYouGet .text-lists {
        margin-bottom: 35px;
    }
    .footerProductWrapper{
        top: 315px;
    }
    .footerC2a .toForm.text-center img {
        width: 545px;
        max-width: 100%;
    }
    #RushOrderFacecream button {
        display: block;
        margin: 0 auto;
    }
    .stayingKetosis h2 {
        font-size: 65px;
    }
    .formBox{
        width: 500px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .checkout-14-order-banner .formBox{
        width: 350px;
    }
    .checkout-14-order-banner .productDiv > div:first-child,
    .checkout-14-order-banner .productDiv > div:last-child{
        flex: 0 0 100%;
        max-width: 100%;
    }
    .form-holder .form-control {
        border-radius: 0;
        font-size: 14px;
    }
    .form-div-1 {
        margin-bottom: 15px;
    }
    .checkoutBtn-div {
        margin-top: 15px;
    }
}

@media screen and (min-width: 767.5px) and (max-width: 767.5px){
    
}

@media screen and (max-width: 767.5px){
    img.Step1_Product-col-sm-4 {
        width: 250px;
        margin-bottom: 30px;
    }
    .bannerLeft {
        position: relative;
        /* max-width: 570px; */
        margin-left: auto;
        /* padding-top: 0; */
    }
    img.Top_Logo {
        right: 60px;
    }
    .grabber-img {
        width: 460px;
    }
    img.Step1_Product {
        width: 165px;
        top: 454px;
        left: unset;
        right: 432px;
    }
    img.Step1_Product-col-sm-5{
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    .formTop img {
        right: 30px;
        width: 70px;
        height: auto;
        top: 200px;
        display: none;
    }
    .stayingKetosis h2 {
        font-size: 55px;
    }
    .footerProductWrapper {
        top: 290px;
    }
    img.Step1_Product-col-sm-offset-2-01, img.Step1_Product-col-sm-offset-2-02, img.Step1_Product-col-sm-offset-2-03 {
        width: 115px;
    }
    img.Step1_Product-col-sm-offset-2-01 {
        left: 55px;
    }
    img.Step1_Product-col-sm-offset-2-03 {
        left: 0;
        right: 0;
        margin: 0 auto;
    }
    img.Step1_Product-col-sm-offset-2-02 {
        left: 250px;
    }
    .ketopiainfo .productImg {
        margin-top: 0;
    }
    .productImg .flowerImg{
        display: none;
    }
    .checkoutpage-14 .formBox{
        width: 100%;
        padding: 0;
    }
    .checkoutpage-14 .checkoutBtn-div {
        margin-top: 20px;
    }
    .form-div-1 {
        margin-bottom: 22px;
    }
    .footerProductWrapper img {
        width: 120px;
    }
    .footerProductWrapper {
        top: 330px;
        width: 270px;
    }

}
@media screen and (max-width: 640.5px){
    img.Step1_Product {
        width: 130px;
        top: 320px;
        left: unset;
        right: 325px;
    }
    .revolutionary-section h2{
        font-size: 25px;
    }
    .theproofD-inner img.Step1_Product-viral {
        width: 220px;
        top: 420px;
        left: -275px;
    }
    .revLeft img{
        display: block;
        margin: 0 auto;
    }
    .os33,
    .ketpoia-ketosis-banner .container h2,
    .mont60 {
        font-size: 25px;
    }
    .os28 {
        font-size: 18px;
    }
    .ketoImg-wrapper {
        flex-wrap: wrap;
    }
    
    img.Logo-Ketpoia {
        width: 200px;
        position: unset;
        margin: 15px auto;
        display: block;
        transform: none;
    }
    .ketoImg-wrapper .product {
        margin-bottom: 50px;
    }
    .whatYouGet h1.mont60 {
        margin-top: 20px;
    }
    .ketpoia-ketosis-banner {
        padding-top: 140px;
        background-position: center;
        min-height: 700px;
    }
    .stayingKetosis h3{
        font-size: 25px;
    }
    .mont50,
    .mont60{
        font-size: 35px;
        margin-bottom: 10px;
    }
    img.Top_Logo{
        height: 65px;
        right: 45px;
    }
    .bannerLeft{
        padding-top: 50px;
    }
    .grabber-img {
        width: 345px;
    }
    
}
@media screen and (max-width: 575.5px){
    .productImg .flowerImg{
        display: none;
    }
    .topAnnoncment{
        font-size: 13px;
    }
    .revLeft {
        padding: 30px 0;
    }
    .formTop img{
        display: none;
    }
    .viraltext {
        font-size: 18px;
        padding: 25px 17px !important;
    }
    /* .grabber-img {
        margin-right: 0;
        width: 100%;
    } */
    .headerbg {
        background-size: 760px;
    }
    .bannerLeft {
        /* width: 360px; */
    }
    /* img.Top_Logo {
        right: 35px;
        left: auto;
        top: 10px;
        height: 60px;
    } */
    /* .grabber-img {
        margin-top: 30px;
    } */
    /* img.Step1_Product {
        width: 170px;
        top: 175px;
        left: -15px;
    } */
    .theproofD{
        padding-top: 100px;
    }
    img.Step1_Product-col-sm-offset-2-02 {
        left: 205px;
    }
    .stayingKetosis h2 {
        font-size: 40px;
    }
    .stayingKetosis h3 {
        font-size: 25px;
    }
    .theproofD-inner img.Step1_Product-viral {
        width: 170px;
        top: auto;
        left: 39px;
        bottom: -120px;
    }
    .img-fluid.center-block.the-proof {
        max-width: 300px;
    }
    .theproofD h1 {
        font-size: 20px;
    }
    .footerC2a {
        min-height: 700px;
    }
    .footerC2a .toForm {
        /* position: relative;
        right: -35px; */
    }
    .footerC2a .toForm.text-center img {
        width: 365px;
    }
    .footerProductWrapper {
        width: 225px;
        top: 160px;
        left: -30px;
    }
    .footerProductWrapper img{
        width: 100px;
    }
    .mont50, .mont60 {
        font-size: 30px;
    }
    .footer-logo {
        width: 190px    ;
    }
    .footerC2a .posRelative{
        padding-top: 0;
    }
    .footer-logo {
        top: -85px;
        left: -65px;
    }
    .theproofD-inner img.Step1_Product-viral {
        width: 115px;
        top: auto;
        left: 15px;
        bottom: -95px;
    }
    .footerC2a{
        padding-top: 240px;
    }

}
@media screen and (max-width: 480.5px){
    img.Step1_Product {
        width: 135px;
        top: 190px;
        left: 0px;
    }
    .topAnnoncment {
        font-size: 12px;
    }
    .footerC2a {
        min-height: 625px;
    }
    .footerC2a .toForm.text-center img {
        width: 300px;
        margin-left: auto;
        margin-right: 0;
    }
    .footerProductWrapper img {
        width: 75px;
    }
    .footerProductWrapper {
        width: 130px;
        top: 135px;
        left: auto;
        right: 100px;
    }
    .footer-logo {
        top: -85px;
        left: auto;
        right: 80px;
    }
}









