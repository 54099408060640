h1, h2, h3, h4, h5, h6 {
    font-family: "Oswald", sans-serif;
}
img {
    max-width: 100%;
}
.gummiesUpsell1 {
    padding-top: 30px;
}
.gummiesUpsell1 .ups {
    height: auto;
    background: 0 0;
    color: #000;
    -webkit-font-smoothing: antialiased;
}
.u-main {
    position: relative;
    padding: 38px 0 55px;
}
.u-heading {
    text-align: center;
    margin: 0 -20px;
}
.u-heading h5 {
    font-size: 18px;
    color: #cb1313;
    font-weight: 700;
    font-family: 'Lato', sans-serif;
    margin: 0 0;
}
.u-heading h1 {
    font-family: "Oswald", sans-serif;
    font-size: 26px;
    color: #000;
    font-weight: 700;
    margin: 15px 0;
}
.u-heading p {
    font-size: 14px;
    line-height: 25px;
    color: #000000;
    font-family: arial;
    margin-bottom: 15px;
}
.ups1 .u-box {
    border-radius: 7px;
    border: 3px dashed #b7b7b7;
    max-width: 865px;
    position: relative;
    background: #fff;
    padding-bottom: 10px;
    margin: 20px auto 18px;
    margin-bottom: 20px;
    background: #eee;
}
.u-left {
    float: left;
    width: 33%;
    position: relative;
}


.ups1 .u-left img {
    margin: 28px auto 8px;
    left: auto;
    width: 170px;
    display: block;
    position: relative;
}
.ups1 .u-left img {
    margin: 28px auto 8px;
    left: auto;
    width: 170px;
    display: block;
    position: relative;
}
.u-price {
    padding: 0 0 0 20px;
    color: #000;
    float: left;
    width: 100%;
    padding-left: 30px;
    display: flex;
}
.ups1 .u-price-left {
    margin: 0;
    padding: 0;
    float: left;
}
.ups1 .u-old-price {
    position: relative;
    line-height: 1;
    color: #7a7979;
    font-size: 30px;
    letter-spacing: -.03em;
    font-family: Oswald, sans-serif;
}
.u-old-price:before {
    position: absolute;
    content: "";
    top: 14px;
    right: -9px;
    left: -9px;
    height: 2px;
    background: red;
    transform: rotate(-19deg);
}
.u-price-left span:last-child {
    font-weight: 700;
    font-family: Lato, sans-serif;
    font-size: 13px;
    line-height: 15px;
    display: block;
    margin-bottom: 3px;
    margin-left: 4px;
    margin-top: 1px;
}

.ups1 .u-new-price {
    font-size: 40px;
    margin: 0 0 0 14px;
    line-height: 62px;
    font-family: Oswald, sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
    float: left;
}

.ups1 .u-new-price font{
    font-size: 14px;
}
.ups1 .u-left .u-label3{
    position: absolute;
    top: 95px;
    right: -45px;
    margin: 0;
    width: 110px;
    left: auto;
    max-width: 100%;
    height: auto;
    vertical-align: top;
}
.u-label {
    position: absolute;
    top: -20px;
    left: -26px;
}

.u-right {
    float: left;
    width: 67%;
    padding: 11px 10px 0 3px;
    text-align: center;
    padding-right: 14px;
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #4a4a4a;
    position: relative;
    z-index: 1;
}
.logo {
    display: block;
    width: 100%;
    float: none;
    text-align: center;
}
img.header__logoX {
    max-height: 65px;
    width: auto;
}
.u-title-lg {
    display: block;
    text-shadow: 0px 5px 4px rgb(134 2 2 / 88%);
    color: red;
    font-size: 65px;
    line-height: 70px;
    font-style: italic;
    word-spacing: -0.2em;
    margin-bottom: 6px;
    padding-right: 20px;
    letter-spacing: -2px;
    font-weight: 700;
}
.u-title2 {
    font-size: 25px;
    display: block;
    font-weight: 700;
    margin-bottom: 6px;
}
.sub_text {
    font-size: 18px;
    margin: 45px 0 12px;
    color: #000;
    text-transform: uppercase;
    font-weight: 900;
    font-style: italic;
    font-family: Gilroy;
}
.u-right p {
    font-size: 14px;
    line-height: 24px;
    margin: 0 0 8px 32px;
    word-spacing: 0.07em;
}

.btnSet {
    text-align: center;
    padding-bottom: 0px;
}
.only_text2 {
    font-size: 30px;
    color: #cb1313;
    margin: 0px 0 20px;
    text-transform: uppercase;
}
.checkout_btn2 {
    width: 660px;
    max-width: 100%;
    text-decoration: none;
    display: inline-block;
    padding: 15px 0px;
    font-size: 30px;
    color: #fff;
    position: relative;
    font-weight: 400;
    text-align: center;
    border-radius: 10px;
    cursor: pointer;
    transition: 300ms ease-in-out;
    -webkit-transition: 300ms ease-in-out;
    border: 1px solid #4c6436;
    background: rgba(159,234,97,1);
    text-shadow: 0px 2px 2px rgb(33 6 52 / 20%);
    background: -moz-linear-gradient(top, rgba(159,234,97,1) 0%, rgba(120,189,59,1) 51%, rgba(71,142,9,1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(159,234,97,1)), color-stop(51%, rgba(120,189,59,1)), color-stop(100%, rgba(71,142,9,1)));
    background: -webkit-linear-gradient(top, rgba(159,234,97,1) 0%, rgba(120,189,59,1) 51%, rgba(71,142,9,1) 100%);
    background: -o-linear-gradient(top, rgba(159,234,97,1) 0%, rgba(120,189,59,1) 51%, rgba(71,142,9,1) 100%);
    background: -ms-linear-gradient(top, rgba(159,234,97,1) 0%, rgba(120,189,59,1) 51%, rgba(71,142,9,1) 100%);
    background: linear-gradient(to bottom, rgba(159,234,97,1) 0%, rgba(120,189,59,1) 51%, rgba(71,142,9,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9fea61', endColorstr='#478e09', GradientType=0 );
}

.btnSet .pic2 {
    margin: 20px 0;
}
.u-button__no {
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    color: #8e8e8e;
    margin-top: 15px;
    line-height: 20px;
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 400;
    margin-top: 20px;
    border-radius: 5px;
    background: transparent;
    padding: 10px;
    transition: all 0.4s;
    display: inline-block;
}
.u-button__no:hover{
    background: #dc3545;
    color: #fff;
}
.u-button__no:last-child{
    margin-bottom: 0;
}
.btnSet .cross {
    font-size: 20px;
    color: red;
    display: inline-block;
    font-weight: bold;
    transition: all 0.4s;
}
.btnSet .u-button__no:hover .cross{
    color: #fff;
}
.comnt_area2 {
    background: #fff;
    padding: 50px 0 30px;
    border-top: 3px solid #b7b7b7;
    border-bottom: 0px solid #b7b7b7;
}
.connection_text span {
    display: block;
    margin: 0 0 20px;
    text-align: center;
}


/* Gummies Upsell 2  */
.doNotExitAlert {
    background: #ca0808;
    color: #fff;
    border-bottom: #fff dashed 3px;
}

.doNotExitAlert h2{
    font-family: "Oswald", sans-serif;
}
.yellowBg {
    background-color: yellow;
}
.couponCountdown {
    border: #ca0808 dashed 3px;
}
.couponCountdown .yellowBg {
    display: inline-block;
    padding: 0 10px;
}
.smallText {
    font-size: 9px;
}

.timer-container{
    border: 1px solid #343a40;
    border-radius: 0.25rem;
}
.couponCountdown #timerbox img {
    margin-top: -8px;
}
.timerText {
    font-size: 37px;
}
.medText {
    font-size: 11px;
}

.contentBox p{
    font-size: 14px;
}

.contentBox p.largeText{
    font-size: 18px;
}
.inputBtn{
    border: 1px solid #000;
}

.gummiesUpsell2 .bottom-nothanks .btn{
    font-size: 10px !important;
    background: transparent;
    border: none;
    color: #8e8e8e;
}

.gummiesUpsell2 .bottom-nothanks .btn:hover{
    background: #dc3545;
    color: #fff;
}
.gummiesUpsell2 .bottom-nothanks .btn:hover .text-danger{
    color: #fff !important;
}
/* Responsive  */
@media only screen and (max-width: 992.9px) {
    .ups1 .u-left .u-label3{
        width: 90px;
    }
}
@media only screen and (max-width: 767.9px) {
    .u-left, .u-right {
        float: none;
        width: 100%;
    }
    .u-right{
        padding: 10px;
    }
    .u-label{
        left: -10px;
    }
    .ups1 .u-left .u-label3 {
        width: 90px;
        left: 50%;
        right: auto;
        transform: translate(80px, -20px);
    }
    .u-price{
        align-items: center;
        justify-content: center;
        padding: 0 15px;
    }
    .u-heading{
        margin: 0 0px;
    }
    .u-main{
        padding-top: 15px;
    }
    .u-right p{
        margin-left: 0;
    }
    .checkout_btn2{
        font-size: 25px;
    }
    .doNotExitAlert {
        padding: 5px 0;
    }
    .gummiesUpsell2 .sticky-top.doNotExitAlert h2 {
        font-size: 16px;
        margin: 0;
    }
    .couponCountdown > div{
        padding: 15px 10px;
    }
}


@media only screen and (max-width: 480.9px){
    .ups1 .u-left .u-label3 {
        width: 80px;
    }
}